import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import settings from '../appsettings';

// -----------------------------------------------------------------------------------------------------
//  SIGNALR config

export interface AuctionData {
    AuctionBatchId: number;
    AuctionId: number;
    ContactId: number;
    HighestBidderId: number;
    CarId: number;
    LastUpdated: string;

    CurrentPrice: number;
    MaxPersonalPrice: number;
    NextMaxPrice: number;
    CurrencyCodeId: string;

    BatchEndDate: string;
}

export interface AuctionBatchData {
    AuctionBatchId: number;
    EndDate: string;
}

export interface AuctionOrder {
    AuctionId: number;
    ExtendedPhaseStatusId: number;
    OrderId: number;
}

export interface XTimeData {
    AuctionBatchId: number;
    AuctionId: number;
    CurrentPrice: number;
    CurrencyCodeId: string;
    BidOptions: number[];
    EndDateExtendedPhase: string;
    Auctions: AuctionOrder[];
    ExtendedPhaseStatusId?: number;
}

export interface ClientAuctionData {
    ContactId: number;
    InLast5Mins: number;
}

export interface ClientAuctionAddOrRemove {
    ContactId: number;
    Add: boolean;
}

export type SignalrCallbacks = {
    broadcastServerTime?(serverDate: string);

    auctionsExtended?(auctionData: AuctionData[]);
    auctionExtended?(auctionData: AuctionData);
    auctionBatchExtended?(auctionBatchData: AuctionBatchData);
    addAuctionBidForClient?(auctionData: AuctionData);
    addAuctionWatchForClient?(auctionData: AuctionData);
    removeBidWatchForClient?(auctionData: AuctionData);
    auctionWithdrawn?(auctionData: AuctionData);

    xTimeAuctionBatchEnd?(auctionBatchData: AuctionBatchData);
    xTimeMessage?(xTimeData: XTimeData);
    xTimeAuctionFinished?(xTimeData: XTimeData);
    xTimePendingMessage?(data: XTimeData);

    auctionsInLast5Mins?(data: ClientAuctionData[]);
    auctionsInLast5MinsAddOrRemove?(data: ClientAuctionAddOrRemove);
    ultimoStarted?(contactIds: number[]);

    provisionalStateChanged?(auctionId: number, newState: string, amount: number);

    orderDocumentReady?(auctionId: number, documentType: string);
    orderCompleted?(auctionId: number);
};

// Actions sent to the dispatch (redux) in signalr event handlers.
export const actions = {
    EVENT_EXTENDED: 'SIGNALR:EVENT_EXTENDED',
    EVENT_WATCHED: 'SIGNALR:EVENT_WATCHED',
    EVENT_BID: 'SIGNALR:EVENT_BID',
    EVENT_REMOVE_WATCH: 'SIGNALR:EVENT_REMOVE_WATCH',
    EVENT_WITHDRAWN: 'SIGNALR:EVENT_WITHDRAWN',
    EVENT_BATCH_EXTENDED: 'SIGNALR:EVENT_BATCH_EXTENDED',
    EVENT_XTIME_MESSAGE: 'SIGNALR:EVENT_XTIME_MESSAGE',
    EVENT_XTIME_FINISHED: 'SIGNALR:EVENT_XTIME_FINISHED',
    EVENT_XTIME_BATCH_END: 'SIGNALR:EVENT_XTIME_BATCH_END',
    EVENT_BROADCAST_SERVER_TIME: 'SIGNALR:EVENT_BROADCAST_SERVER_TIME',
};

export class SignalrClient {
    private connection: HubConnection;

    constructor() {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${settings.signalr.url}?key=${settings.signalr.apiKey}`, {
                transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Debug)
            .build();

        this.connection.onclose(() => {
            console.info('[signalr] connection closed');
        });

        this.connection.onreconnecting(() => {
            console.info('[signalr] connection reconnecting');
        });

        this.connection.onreconnected(() => {
            console.info('[signalr] connection reconnected');
        });
    }

    async connect() {
        return this.connection
            .start()
            .then(() => {
                console.log('[signalr] connected');
            })
            .catch(error => {
                console.log(error);
            });
    }

    async registerUser(): Promise<void> {
        return this.connection.invoke('registerPowerUser');
    }

    async registerForAuctionBatchs(auctionBatchIds: number[]): Promise<void> {
        return this.connection.invoke('registerForAuctionBatchIds', auctionBatchIds);
    }

    async registerForAuctions(auctionIds: number[]): Promise<void> {
        return this.connection.invoke('registerForAuctionIds', auctionIds);
    }

    async registerForContact(contactId: number): Promise<void> {
        return this.connection.invoke('registerForContactId', contactId);
    }

    async registerForSession(sessionId: number): Promise<void> {
        return this.connection.invoke('registerForSessionId', sessionId.toString());
    }

    async unregisterForAuctionId(auctionId: number): Promise<void> {
        return this.connection.invoke('unregisterForAuctionId', auctionId);
    }

    /**
     * Method which listens to signalR events and calls
     * action creators which will change state
     */
    handleEvents(callbacks: SignalrCallbacks): any {
        function pascalCaseObjectKeys(obj: any) {
            const pascalCaseKey = (key: string) => key.charAt(0).toUpperCase() + key.slice(1);

            const pascalCaseObject = (inputObj: any): any => {
                return Object.keys(inputObj).reduce((acc, key) => {
                    const newKey = pascalCaseKey(key);
                    acc[newKey] = inputObj[key];
                    return acc;
                }, {});
            };

            return pascalCaseObject(obj);
        }
        this.connection.on('auctionExtended', auctionData => {
            console.log('[signalr] auctionExtended', auctionData);
            if (callbacks.auctionExtended) callbacks.auctionExtended(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('auctionBatchExtended', auctionBatchData => {
            console.log('[signalr] auctionBatchExtended', auctionBatchData);
            if (callbacks.auctionBatchExtended) callbacks.auctionBatchExtended(pascalCaseObjectKeys(auctionBatchData));
        });
        this.connection.on('addAuctionBidForClient', auctionData => {
            console.log('[signalr] addAuctionBidForClient', auctionData);
            if (callbacks.addAuctionBidForClient) callbacks.addAuctionBidForClient(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('addAuctionWatchForClient', auctionData => {
            console.log('[signalr] addAuctionWatchForClient', auctionData);
            if (callbacks.addAuctionWatchForClient) callbacks.addAuctionWatchForClient(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('removeBidWatchForClient', auctionData => {
            console.log('[signalr] removeBidWatchForClient', auctionData);
            if (callbacks.removeBidWatchForClient) callbacks.removeBidWatchForClient(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('auctionWithdrawn', auctionData => {
            console.log('[signalr] auctionWithdrawn', auctionData);
            if (callbacks.auctionWithdrawn) callbacks.auctionWithdrawn(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('xTimeAuctionBatchEnd', auctionBatchData => {
            console.log('[signalr] xTimeAuctionBatchEnd', auctionBatchData);
            if (callbacks.xTimeAuctionBatchEnd) callbacks.xTimeAuctionBatchEnd(pascalCaseObjectKeys(auctionBatchData));
        });
        this.connection.on('xTimeMessage', xTimeData => {
            console.log('[signalr] xTimeMessage', xTimeData);
            if (callbacks.xTimeMessage) callbacks.xTimeMessage(pascalCaseObjectKeys(xTimeData));
        });
        this.connection.on('xTimeAuctionFinished', xTimeData => {
            console.log('[signalr] xTimeAuctionFinished', xTimeData);
            if (callbacks.xTimeAuctionFinished) callbacks.xTimeAuctionFinished(pascalCaseObjectKeys(xTimeData));
        });
        this.connection.on('broadcastServerTime', serverDate => {
            console.log('[signalr] broadcastServerTime', serverDate);
            if (callbacks.broadcastServerTime) callbacks.broadcastServerTime(serverDate);
        });
        this.connection.on('XTimePendingMessage', auctionData => {
            console.log('[signalr] xTimePendingMessage', auctionData);
            if (callbacks.xTimePendingMessage) callbacks.xTimePendingMessage(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('auctionsInLast5Mins', auctionData => {
            console.log('[signalr] auctionsInLast5Mins', auctionData);
            if (callbacks.auctionsInLast5Mins) callbacks.auctionsInLast5Mins(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('auctionsInLast5MinsAddOrRemove', auctionData => {
            console.log('[signalr] auctionsInLast5MinsAddOrRemove', auctionData);
            if (callbacks.auctionsInLast5MinsAddOrRemove) callbacks.auctionsInLast5MinsAddOrRemove(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('ultimoStarted', auctionData => {
            console.log('[signalr] ultimoStarted', auctionData);
            if (callbacks.ultimoStarted) callbacks.ultimoStarted(pascalCaseObjectKeys(auctionData));
        });
        this.connection.on('provisionalStateChanged', (auctionId, newState, amount) => {
            console.log('[signalr] provisionalStateChanged', auctionId, newState, amount);
            if (callbacks.provisionalStateChanged) callbacks.provisionalStateChanged(auctionId, newState, amount);
        });
        this.connection.on('orderDocumentReady', ({ auctionId, contactId, documentType }) => {
            console.log('[signalr] orderDocumentReady', auctionId, contactId, documentType);
            if (callbacks.orderDocumentReady) callbacks.orderDocumentReady(auctionId, documentType);
        });
        this.connection.on('orderCompleted', ({ auctionId, contactId }) => {
            console.log('[signalr] orderCompleted', auctionId, contactId);
            if (callbacks.orderCompleted) callbacks.orderCompleted(auctionId);
        });
    }
}
