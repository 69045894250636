import { notify } from 'reapop';
import i18n from '../i18n';
import { csrfToken, csrfTokenName, parsedQueryString } from '../utils';
import { getSEOPage } from '../routing';
import { BannerSessionStorageKeys } from '../hooks/useBanner';

export enum CompanyOrContactBlocked {
    COMPANY = 'login.companyblocked', // backend constant value
    CONTACT = 'login.contactblocked', // backend constant value
}

type LoginState = {
    username: string;
    isOktaInitialized: boolean;
    loading: boolean;
    companyOrContactBlocked?: CompanyOrContactBlocked;
    companyBlockedReasonId?: number;
};

/***************************************************************************************************************
 *  REDUX ACTIONS
 */
const actions = {
    SET_LOGIN_USERNAME: 'LOGIN:SET_LOGIN_USERNAME',
    CLEAR: 'LOGIN:CLEAR',
    OKTA_INITIALIZED: 'LOGIN:OKTA_INITIALIZED',
    SET_LOADING: 'LOGIN:SET_LOADING',
    SET_BLOCKED: 'LOGIN:SET_BLOCKED',
};
type LoginAction = keyof typeof actions;

const initialState: LoginState = { username: '', isOktaInitialized: false, loading: false };

/***************************************************************************************************************
 *  REDUX REDUCER
 */
type PayloadAction = { type: LoginAction } & LoginState;

export function loginReducer(state = initialState, action: PayloadAction) {
    switch (action.type) {
        case actions.SET_LOGIN_USERNAME:
            return { ...state, username: action.username };
        case actions.CLEAR:
            return { ...state, username: '' };
        case actions.OKTA_INITIALIZED:
            return {
                ...state,
                isOktaInitialized: true,
            };
        case actions.SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case actions.SET_BLOCKED:
            return {
                ...state,
                companyOrContactBlocked: action.companyOrContactBlocked,
                companyBlockedReasonId: action.companyBlockedReasonId,
            };
        default:
            return { ...state };
    }
}

/***************************************************************************************************************
 *  REACT-REDUX state to properties map
 */
export const loginMapStateToProps = globalState => ({
    usernameLogin: globalState.login.username,
    isOktaInitialized: globalState.login.isOktaInitialized,
    loading: globalState.login.loading,
    companyOrContactBlocked: globalState.login.companyOrContactBlocked,
    companyBlockedReasonId: globalState.login.companyBlockedReasonId,
});

/***************************************************************************************************************
 *  REACT-REDUX action creators (using redux-thunk: Redux Thunk middleware allows you to write action creators that return a function instead of an action)
 */
export const loginActionsCreator = {
    onSetLoginUsername: username => dispatch => dispatch({ type: actions.SET_LOGIN_USERNAME, username }),
    onOktaInitialized: () => dispatch => dispatch({ type: actions.OKTA_INITIALIZED }),
    onClear: () => dispatch => dispatch({ type: actions.CLEAR }),
    onPreLogin: (username, isRememberMe) => (dispatch, getState) => preLogin(username, isRememberMe, dispatch, getState),
    onLogin: (username, password, isRememberMe) => (dispatch, getState) => login(username, password, isRememberMe, dispatch, getState),
};

function preLogin(username, isRememberMe, dispatch, getState) {
    if (username === null) {
        // clear username.
        dispatch({ type: actions.SET_LOGIN_USERNAME, username: null });
        return;
    }
    dispatch({ type: actions.SET_LOADING, loading: true });
    const state = getState();
    const currentLanguage = state.common.currentLanguage;

    const body = new FormData();
    body.append('Email', username);
    body.append('IsRememberMe', isRememberMe);

    fetch(`/${currentLanguage}/Login/ValidateOktaUser?username=${username}`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json', //????
            [csrfTokenName]: csrfToken(),
        },
        body: body,
    })
        .then(r => r.json())
        .catch(e => console.error('LOGIN ERROR', e))
        .then(resp => {
            if (!resp.ErrorMsg) {
                if (resp.isOktaUser) {
                    // redirect to okta sign-in widget
                    const oktaLoginUrl = '/' + currentLanguage + '/login/okta';
                    //setting the okta user in local storage for persisting the username
                    localStorage.setItem('oktaUser', username);
                    dispatch({ type: actions.SET_LOADING, loading: false });
                    //window.history.pushState({ urlPath: oktaLoginUrl }, '', oktaLoginUrl);
                    window.location.href = oktaLoginUrl;
                } else {
                    dispatch({ type: actions.SET_LOGIN_USERNAME, username });
                    dispatch({ type: actions.SET_LOADING, loading: false });
                }
            } else {
                console.error('Login error:', resp.ErrorMsg);
                dispatch(notify({ message: i18n.t('login.errorMessage') as string, status: 'error' }));
                dispatch({ type: actions.SET_LOADING, loading: false });
            }
        });
}

function login(username, password, isRememberMe, dispatch, getState) {
    const state = getState();
    const currentLanguage = state.common.currentLanguage;
    dispatch({ type: actions.SET_LOADING, loading: true });
    // Login to carsite and redirect to the same page if successful.
    const body = new FormData();
    body.append('Email', username);
    body.append('Password', password);
    body.append('IsRememberMe', isRememberMe);
    // check for impersonate param in query.
    const queryParams = parsedQueryString(window.location.search);
    if (queryParams.impersonate) {
        body.append('ImpersonateContactId', queryParams.impersonate);
    }

    return fetch(`/${currentLanguage}/Login/Login`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'application/json',
            [csrfTokenName]: csrfToken(),
        },
        body: body,
    })
        .then(r => r.json())
        .catch(e => console.error('LOGIN ERROR', e))
        .then(resp => {
            if (!resp.ErrorMsg) {
                // remove banners visibiliy session storage
                for (const key in BannerSessionStorageKeys) {
                    window.sessionStorage.removeItem(`OPENLANE:bannerVisibility:${BannerSessionStorageKeys[key]}`);
                }

                if (resp.Redirect_URL) {
                    // redirect after login (following not valid anti forgery token).
                    var url = resp.Redirect_URL;
                    var oldLanguage = '/' + url.split('/')[1] + '/';
                    if (oldLanguage === '//') {
                        oldLanguage = '/' + url.split('/')[3] + '/';
                    }
                    var newLanguage = '/' + resp.lang + '/';
                    var seoPage = getSEOPage();
                    if (seoPage !== undefined) {
                        window.location.href = seoPage.t.replace(oldLanguage, newLanguage).replace('#', '');
                    } else {
                        window.location.href = url.replace(oldLanguage, newLanguage).replace('#', '');
                    }
                } else if (resp.ErrorCode && resp.ErrorCode === 'AntiForgeryTokenIsNotValid') {
                    // refresh anti forgery token & force login on the home page.
                    window.location.href = '/' + currentLanguage + '/home/Login';
                    dispatch({ type: actions.SET_LOADING, loading: false });
                } else {
                    // success: refresh the page.
                    window.location.href = window.location.pathname + window.location.search;
                }
            } else {
                switch (resp.CompanyOrContactBlocked) {
                    case CompanyOrContactBlocked.COMPANY:
                        dispatch({
                            type: actions.SET_BLOCKED,
                            companyOrContactBlocked: resp.CompanyOrContactBlocked,
                            companyBlockedReasonId: resp.CompanyBlockedReasonId,
                        });
                        break;
                    case CompanyOrContactBlocked.CONTACT:
                        dispatch({ type: actions.SET_BLOCKED, companyOrContactBlocked: resp.CompanyOrContactBlocked });
                        break;
                    default:
                        console.error('Login error:', resp.Message);
                        dispatch(notify({ message: i18n.t('login.errorMessage') as string, status: 'error' }));
                }
                dispatch({ type: actions.SET_LOADING, loading: false });
            }
        });
}
