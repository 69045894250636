import React, { useLayoutEffect, useState } from 'react';
import './UnblockAccountAgreement.less';
import Footer from '../components/Layout/Footer';
import { Button, Checkbox, PageTitle, Spinner } from '@ambiorix/adesa-web-kit';
import { getParsedQueryString } from '../routing';
import { Trans } from 'react-i18next';

const linkKeyEu = 'accountNearlyUnblocked.t&c.link';
const linkKeyUk = 'accountNearlyUnblocked.t&c.linkUk';

const UnblockAccountAgreement = ({ currentUser, currentLanguage, t, notify, onUnblockAccountAfterInactivity }) => {
    const [agreed, setAgreed] = useState(false);
    const [unblockLoading, setUnblockLoading] = useState(false);
    const [isUk, setIsUk] = useState(false);

    useLayoutEffect(() => {
        const { key } = getParsedQueryString();

        if (!key) window.location.href = `/${currentLanguage}/home`;

        if (currentUser.visitingUserCountry) {
            const ukCountryIds = ['uk', 'gb'];
            const visitingFromUk = ukCountryIds.includes(currentUser.visitingUserCountry.toLowerCase());
            setIsUk(visitingFromUk);
        } else {
            const hostname = window.location.hostname;
            setIsUk(hostname.endsWith('.co.uk'));
        }
    }, []);

    const handleUnblock = async () => {
        setUnblockLoading(true);
        const { key } = getParsedQueryString();
        await onUnblockAccountAfterInactivity(key);
        setUnblockLoading(false);
    };

    const urlLink = () => {
        const linkKey = isUk ? linkKeyUk : linkKeyEu;

        const defaultLink = isUk
            ? `https://www.openlane.eu/${currentLanguage}/cms/terms-and-conditions-uk`
            : `https://www.openlane.eu/${currentLanguage}/cms/terms-and-conditions`;

        // if url not translated, return default link
        return `${t(linkKey) === linkKey ? defaultLink : t(linkKey)}`;
    };

    return (
        <>
            <div className="unblock-body">
                <div className="unblock-container">
                    <div className="unblock-openlane-logo-div">
                        <a href="/">
                            <img className="openlane-logo" src="/v6/images/openlane-logo.svg" title="Openlane" alt="Openlane" />
                        </a>
                    </div>

                    <PageTitle>{t('accountNearlyUnblocked.title')}</PageTitle>

                    <div className="unblock-card">
                        <span className="unblock-instruction">
                            <Trans
                                t={t}
                                i18nKey={'accountNearlyUnblocked.description'}
                                components={{
                                    a: <a href={urlLink()} />,
                                }}
                            />
                        </span>

                        <Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} className="unblock-checkbox" variant="basic">
                            {t('accountNearlyUnblocked.condition')}
                        </Checkbox>
                        {!agreed && <span className="unblock-checkbox-indication">{t('accountNearlyUnblocked.error')}</span>}
                        <Button disabled={!agreed || unblockLoading} onClick={handleUnblock} prefix={unblockLoading && <Spinner />}>
                            {t('common.action.unblockMyAccount')}
                        </Button>
                    </div>
                </div>
            </div>
            <Footer currentLanguage={currentLanguage} t={t} />
        </>
    );
};

export default UnblockAccountAgreement;
