import { queryString, scrollToTop, parsedQueryString, extrapolate, fetchJson, uuidv4, distinct, logAnalytics } from '../../utils';
import { belgiumDateTimeToLocal } from '../../utils/date';
import { notify } from 'reapop';
import i18n from '../../i18n';
import settings from '../../appsettings';
import {
    rangeSearchQueryNameMapping,
    checkboxSearchQueryNameMapping,
    facetsMapping,
    metadataFields,
    auctionToState,
    signalrAuctionDataToState,
    savedQueryToState,
} from './mappings';
import { isSearchPage, getParsedQueryString } from '../../routing';
import { sortingPreferenceStorageKey } from './common';

export { carSearchReducer } from './reducer';

//import metadata from "./demoData/metadata.json";

import { replace, push, goBack } from 'connected-react-router';

import {
    INIT_METADATA,
    START_SEARCH,
    NEW_SEARCH_RESULT,
    NEXT_SEARCH_RESULT,
    CHANGE_PAGE_SIZE,
    CHANGE_SORTING,
    CLEAR_SEARCH_CRITERIA,
    CHANGE_SEARCH_CRITERIA,
    SIGNALR_BID,
    AUCTION_EXTENDED,
    AUCTION_CLOSE_EXTENDED,
    SHOW_HIDE_FACET,
    ADD_FACET_COUNTS,
    CLOSE_AUCTION,
    SAVED_SEARCHES_GET,
    SAVED_SEARCHES_CREATE,
    SAVED_SEARCHES_UPDATE,
    SAVED_SEARCHES_DELETE,
    SAVED_SEARCHES_SELECT,
    CHANGE_POWER_UNIT,
    SET_AUCTIONBATCH_TITLE,
    SET_SEO_TEXT,
    START_FETCHING_COMMUNITY,
    COMMUNITY_FECHED,
    SET_LOCATION_METADATA,
    XTIME_MESSAGE,
} from './actions';

const extraFacetsToRequest = ['CleanModel', 'CountryCodeDealer', 'CommunityId']; //Add VehicleLocation  TBD
const extraFacetsToRequestCommunityNonTransactional = ['NonTransactionalCountries'];

//-----------------------------------------------------------------------------------------------------------------

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

let signalrClient = null;

export const carSearchMapStateToProps = state => ({
    serverDate: state.common.serverDate,
    labels: state.carSearch.labels,
    metadata: state.carSearch.metadata,
    manageQueriesUrl: state.carSearch.manageQueriesUrl,
    searching: state.carSearch.searching,
    savedSearches: state.carSearch.savedSearches,
    selectedSavedSearch: state.carSearch.selectedSavedSearch,
    searchCriteria: state.carSearch.searchCriteria,
    visibleFacets: state.carSearch.visibleFacets,
    auctionsCount: state.carSearch.auctionsCount,
    auctionsList: state.carSearch.auctionsList.map(a => ({
        ...a,
        following: state.common.watches.indexOf(a.auctionId) > -1,
    })),
    paging: state.carSearch.paging,
    sorting: state.carSearch.sorting,
    currentPowerUnit: state.carSearch.currentPowerUnit,
    dummy: state.carSearch.dummy,

    currentAuctionBatchId: state.carSearch.searchCriteria.currentAuctionBatchId,
    auctionIds: state.carSearch.searchCriteria.auctionIds,
    //currentAuctionBatchName: auctionBatchName(state.carSearch.searchCriteria.currentAuctionBatchId, state.auctionBatches.auctionBatchesList, state.common.currentLanguage),
    currentAuctionBatchName: state.carSearch.auctionBatchTitlesByLanguage[state.common.currentLanguage] || '',
    currentCommunityId: state.carSearch.searchCriteria.currentCommunityId,
    seoText: state.carSearch.seoText,
    currentCommunity: state.carSearch.currentCommunity,
    vehicleLocation: state.carSearch.vehicleLocation,
});

export const carSearchActionsCreator = /*(dispatch, getState) =>*/ {
    onInit: (signalr, communityId) => (dispatch, getState) => {
        signalrClient = signalr;
        if (communityId) fetchCommunity(communityId, dispatch, getState);
        return initializeSearch(dispatch, getState);
    },

    // signalr events.
    onAddAuctionBidForClient: auctionData => (dispatch, getState) => otherBidReceived(auctionData, getState(), dispatch),
    onAuctionExtended: auctionData => (dispatch, getState) =>
        dispatch({ type: AUCTION_EXTENDED, auctionId: auctionData.AuctionId, endDate: belgiumDateTimeToLocal(auctionData.BatchEndDate) }),
    onXTimeAuctionFinished: auctionData => (dispatch, getState) => dispatch({ type: AUCTION_CLOSE_EXTENDED, auctionId: auctionData.AuctionId }),
    onXTimeMessage: auctionData => (dispatch, getState) =>
        dispatch({
            type: XTIME_MESSAGE,
            auctionId: auctionData.AuctionId,
            currentPrice: auctionData.CurrentPrice,
            endDate: belgiumDateTimeToLocal(auctionData.EndDateExtendedPhase),
        }),

    onClearSearchSelection: () => (dispatch, getState) => {
        dispatch({ type: CLEAR_SEARCH_CRITERIA });
        const state = getState();
        setUrlQueryStringFromSearchCriteria(state.carSearch.searchCriteria, dispatch);
        search(dispatch, getState);
    },
    onChangeSearchSelection: (criteriaName, newSelection) => (dispatch, getState) => {
        dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName, newSelection, searchGuid: uuidv4() });
        const state = getState();
        const isHomePage = /\/home\b/i.test(location.pathname);
        // skip updating url when changing criteria in search form in home page.
        if (!isHomePage) setUrlQueryStringFromSearchCriteria(state.carSearch.searchCriteria, dispatch);
        search(dispatch, getState);
    },
    onTypedZipCode: zipCode => (dispatch, getState) => searchZipCodes(zipCode, dispatch, getState),

    //onSearch: () => (dispatch, getState) => goToCarsiteResultPage(dispatch, getState()),
    onSearch: () => (dispatch, getState) => search(dispatch, getState, 0),
    onPageLoad: pageIndex => (dispatch, getState) => {
        search(dispatch, getState, pageIndex);
        scrollToTop();
    },
    onPageSize: pageSize => (dispatch, getState) => {
        dispatch({ type: CHANGE_PAGE_SIZE, pageSize });
        search(dispatch, getState, 0);
        scrollToTop();
    },
    onSort: sorting => (dispatch, getState) => {
        dispatch({ type: CHANGE_SORTING, sorting });
        search(dispatch, getState, 0);
        localStorage.setItem(sortingPreferenceStorageKey, JSON.stringify(getState().carSearch.sorting));
        scrollToTop();
    },

    onShowFilterScreen: () => (dispatch, getState) => dispatch(push(`/${getState().common.currentLanguage}/findcar/filter`)),
    onShowResultsList: () => (dispatch, getState) => {
        if (/\/filter\b/.test(location.pathname)) {
            dispatch(goBack());
        } else {
            dispatch(push(`/${getState().common.currentLanguage}/findcar`));
            scrollToTop();
        }
    },
    onShowSavedSearches: () => (dispatch, getState) => {
        const state = getState();
        if (!/\/findcar\/saved$/i.test(location.pathname)) {
            // check if direct access on the savesearch page via url bar.
            dispatch(push(`/${state.common.currentLanguage}/findcar/saved`));
        }
        fetchSavedSearches(dispatch, state);
    },

    onShowSavedSearch: id => (dispatch, getState) => {
        // fetch the saved search and redirect to the search result page...
        const state = getState();
        if (state.common.isUserLoggedIn) {
            fetchSavedSearches(dispatch, state).then(() => {
                selectSavedSearch(id, dispatch, getState);
            });
        } else {
            // #3381: not loggedin user should be able to see the result of a query when clicking the link on the received email.
            fetchSavedSearch(id, dispatch, state).then(() => selectSavedSearch(id, dispatch, getState));
        }
    },

    // SAVED SEARCHES related action creators.
    onCreateSavedSearch: savedSearch => (dispatch, getState) => {
        createSavedSearch(savedSearch, dispatch, getState());
        dispatch(
            notify({
                message:
                    extrapolate(i18n.t('toast.flashing.searchCreatedSuccessfully'), savedSearch.name) +
                    ' ' +
                    i18n.t('toast.flashing.searchAvailableIn') +
                    ' ' +
                    i18n.t('toast.flashing.searchYourSavedSearches'),
                status: 'success',
            }),
        );
    },
    onUpdateSavedSearch: savedSearch => (dispatch, getState) => updateSavedSearch(savedSearch, dispatch, getState()),
    onDeleteSavedSearches: ids => (dispatch, getState) => deleteSavedSearches(ids, dispatch, getState()),
    onSelectSavedSearch: id => (dispatch, getState) => {
        selectSavedSearch(id, dispatch, getState);
        scrollToTop();
    },
    onBackToSearchPage: () => (dispatch, getState) => {
        setUrlQueryStringFromSearchCriteria(getState().carSearch.searchCriteria, dispatch);
        document.title = i18n.t('meta.title.findcar');
        const state = getState();
        if (state.carSearch.auctionsCount === 0) {
            search(dispatch, getState, 0);
        }
    },

    onShowAuctionBatch: auctionBatchId => (dispatch, getState) => {
        // show search page with auctions related to selected auction batch.
        dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName: 'currentAuctionBatchId', newSelection: auctionBatchId, searchGuid: uuidv4() });
        search(dispatch, getState, 0);
        dispatch(push(`/${getState().common.currentLanguage}/findcar`));
        getAuctionBatchTitle(auctionBatchId, getState(), dispatch);
        scrollToTop();
    },

    onShowCommunityAuctions: communityId => (dispatch, getState) => {
        // show search page with auctions related to selected community.
        dispatch({
            type: CHANGE_SEARCH_CRITERIA,
            criteriaName: 'currentCommunityId',
            newSelection: communityId,
            searchGuid: uuidv4(),
        });
        search(dispatch, getState, 0);
        dispatch(push(`/${getState().common.currentLanguage}/findcar`));
        fetchCommunity(communityId, dispatch, getState);
        scrollToTop();
    },

    // onShowVehicleLocationAuctions: () => (dispatch, getState) => {
    //     //show serach page with auctions related to vehicleLocation TBD ----
    // },
    onShowAuction: (auctionId, reference) => (dispatch, getState) => {
        dispatch(push(`/${getState().common.currentLanguage}/car/info?auctionId=${auctionId}` + (reference ? `&ref=${reference}` : '')));
    },

    onCloseAuction: auctionId => (dispatch, getState) => dispatch({ type: CLOSE_AUCTION, auctionId }),

    onChangePowerUnit: powerUnit => dispatch => {
        dispatch({
            type: CHANGE_SEARCH_CRITERIA,
            criteriaName: powerUnit === 'horsePowers' ? 'kWs' : 'horsePowers',
            newSelection: { from: null, to: null },
            searchGuid: uuidv4(),
        });
        dispatch({ type: CHANGE_POWER_UNIT, powerUnit });
    },

    onShowHideFacet: (open, name) => (dispatch, getState) => {
        dispatch({ type: SHOW_HIDE_FACET, open, name });
        if (open) {
            fetchFacetCounts(name, dispatch, getState());
        }
    },

    onLogAnalytics: (action, message) => (dispatch, getState) => logAnalytics('PremiumOfferTour', action, getState().common.currentLanguage, message),

    onResetCriteria: () => dispatch => dispatch({ type: CLEAR_SEARCH_CRITERIA }),

    onLeaveCommunity: id => (dispatch, getState) => leaveCommunity(id, dispatch, getState),
};

function initializeLocationSearch(dispatch, getState) {
    const state = getState();
    // prefill zipCode in location search facet with current buyer zipCode (main address)
    const mainAddressZipCode = state.common.currentUser.mainAddressZipCode;
    if (mainAddressZipCode) {
        return searchZipCodes(mainAddressZipCode, dispatch, getState).then(() => {
            const zipCodes = getState().carSearch.metadata.vehicleLocation; // list of matching zipCodes
            if (zipCodes.length > 0) {
                const newSelection = {
                    ...state.carSearch.searchCriteria.vehicleLocation,
                    zipCode: `${zipCodes[0].postalCode} ${zipCodes[0].place}`,
                    postalCode: `${zipCodes[0].postalCode}`,
                    place: `${zipCodes[0].place}`,
                    country: state.common.currentUser.country,
                    latitude: zipCodes[0].latitude,
                    longitude: zipCodes[0].longitude,
                    default: {
                        zipCode: `${zipCodes[0].postalCode} ${zipCodes[0].place}`,
                        postalCode: `${zipCodes[0].postalCode}`,
                        place: `${zipCodes[0].place}`,
                        latitude: zipCodes[0].latitude,
                        longitude: zipCodes[0].longitude,
                    },
                };
                dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName: 'vehicleLocation', newSelection, searchGuid: uuidv4() });
            }
        });
    }
    return Promise.resolve();
}

function initializeSearch(dispatch, getState) {
    const state = getState();
    const isUserLoggedIn = state.common.isUserLoggedIn;

    const searchMetadataUrl = url('findcarv6/metadata', state.common.currentLanguage);

    const metadataReady = fetchJson(searchMetadataUrl).then(metadata => {
        dispatch({
            type: INIT_METADATA,
            metadata,
            sellerGroups: state.common.sellerGroups,
            currentLanguage: state.common.currentLanguage /*, savedSearches: []*/,
            allowedCatalogues: state.common.currentUser.allowedCatalogues,
            isUserLoggedIn: state.common.isUserLoggedIn,
        });
    });

    const communities = isUserLoggedIn
        ? fetchJson(url('communitiesv6/JoinedCommunities', state.common.currentLanguage)).then(communities => {
              if (communities) {
                  dispatch({ type: 'COMMON:COMMUNITIES_FETCHED', communities });
              }
          })
        : Promise.resolve();

    const result = Promise.all([metadataReady, communities]).then(() => {
        return initializeLocationSearch(dispatch, getState).then(() => {
            let done = Promise.resolve();
            // if start page is findcar page, take search criteria from URL.
            if (isSearchPage()) {
                setSearchCriteriaFromUrlQueryString(dispatch, state);
                done = search(dispatch, getState, 0);
                // if we are showing a auction batch content -> get the auction batch to display title.
                const auctionBatchId = getState().carSearch.searchCriteria.currentAuctionBatchId;
                if (auctionBatchId) {
                    getAuctionBatchTitle(auctionBatchId, state, dispatch);
                }
            }
            return done;
        });
    });

    return result;
}

function parsedValue(str) {
    if (/^(true|false)$/i.test(str)) {
        return str === 'true';
    }
    if (!/[a-zA-Z]/.test(str)) {
        // issue: "247StockSale" is parsed to 247 so check for chars in field too.
        const possibleNumber = parseInt(str, 10);
        return isNaN(possibleNumber) ? str : possibleNumber;
    } else {
        // sanity clean of the query string value, remove special chars (issue when sending them to redis).
        // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/655374
        return str.replace(/[^\wéèàç\s\-.,]/g, '');
    }
}

function getAuctionBatchTitle(auctionBatchId, state, dispatch) {
    fetchJson(url('findcarv6/auctionbatch?auctionbatchid=' + auctionBatchId, state.common.currentLanguage)).then(resp =>
        dispatch({ type: SET_AUCTIONBATCH_TITLE, auctionBatch: resp.Object }),
    );
}

/**
 * Check the query string of the current URL to prefill the search criteria.
 */
function setSearchCriteriaFromUrlQueryString(dispatch, state) {
    const query = getParsedQueryString();
    if (query) {
        if (query.makesmodels) {
            // format of param: <make1>,<model1a>,<model1b>|<make2>,<model2a>,<model2b>
            const selectedMakesModels = query.makesmodels.split('|').map(list => {
                const [make, ...models] = list.split(',');
                return { make, models };
            });
            dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName: 'makesModels', newSelection: selectedMakesModels, searchGuid: uuidv4() });
        }
        Object.keys(checkboxSearchQueryNameMapping).forEach(n => {
            if (query[n]) {
                dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName: n, newSelection: query[n].split(',').map(parsedValue), searchGuid: uuidv4() });
            }
        });
        Object.keys(rangeSearchQueryNameMapping).forEach(n => {
            if (query[n]) {
                const [from, to] = query[n].split(',').map(parsedValue);
                dispatch({ type: CHANGE_SEARCH_CRITERIA, criteriaName: n, newSelection: { from, to }, searchGuid: uuidv4() });
            }
        });
        if (query.auctionbatchid) {
            dispatch({
                type: CHANGE_SEARCH_CRITERIA,
                criteriaName: 'currentAuctionBatchId',
                newSelection: parseInt(query.auctionbatchid, 10),
                searchGuid: uuidv4(),
            });
        }
        if (query.auctionids) {
            dispatch({
                type: CHANGE_SEARCH_CRITERIA,
                criteriaName: 'auctionIds',
                newSelection: query.auctionids.split(',').map(a => parseInt(a, 10)),
                searchGuid: uuidv4(),
            });
        }
        if (query.communityid) {
            dispatch({
                type: CHANGE_SEARCH_CRITERIA,
                criteriaName: 'currentCommunityId',
                newSelection: parseInt(query.communityid, 10),
                searchGuid: uuidv4(),
            });
        }
        if (query.cmsid) {
            // fetch text to display for SEO purpose. https://dev.azure.com/CarsOnTheWeb/CarsOnTheWeb/_workitems/edit/3119
            fetchJson(url('findcarv6/CmsContentBlock?blockid=' + query.cmsid.toLowerCase(), state.common.currentLanguage)).then(resp =>
                dispatch({ type: SET_SEO_TEXT, seoText: resp }),
            );
        }
        if (query.vehiclelocation) {
            const locationSearchParams = query.vehiclelocation.split(',');
            if (locationSearchParams.length === 6) {
                const [country, postalCode, radius, latitude, longitude, place] = locationSearchParams;
                const currentSelection = state.carSearch.searchCriteria.vehicleLocation;
                dispatch({
                    type: CHANGE_SEARCH_CRITERIA,
                    criteriaName: 'vehicleLocation',
                    newSelection: {
                        ...currentSelection,
                        country,
                        postalCode,
                        radius,
                        latitude,
                        longitude,
                        place,
                        zipCode: `${postalCode} ${place}`,
                    },
                    searchGuid: uuidv4(),
                });
            }
        }
    }
}

/**
 * Adapt the querystring in URL bar based on currently selected criteria.
 */
function setUrlQueryStringFromSearchCriteria(criteria, dispatch) {
    const result = {};
    if (criteria.makesModels && criteria.makesModels.length) {
        result['makesModels'] = criteria.makesModels.map(mm => [mm.make, ...mm.models].join(',')).join('|');
    }
    Object.keys(checkboxSearchQueryNameMapping).forEach(n => {
        if (criteria[n] && criteria[n].length) {
            result[n] = criteria[n].join(','); // [[1,2],[3,4]].join(",") => "1,2,3,4"  (for sellers)
        }
    });
    Object.keys(rangeSearchQueryNameMapping).forEach(n => {
        if (criteria[n] && (criteria[n].from || criteria[n].to)) {
            result[n] = (criteria[n].from || 0) + ',' + (criteria[n].to || 0);
        }
    });
    if (criteria.currentAuctionBatchId) {
        result.auctionBatchId = criteria.currentAuctionBatchId;
    }
    if (criteria.auctionIds && criteria.auctionIds.length > 0) {
        result.auctionIds = criteria.auctionIds.join(',');
    }
    if (criteria.currentCommunityId) {
        result.communityId = criteria.currentCommunityId;
    }
    if (criteria.vehicleLocation && !!criteria.vehicleLocation.radius && !!criteria.vehicleLocation.latitude) {
        const urlQueryValue = [
            criteria.vehicleLocation.country,
            criteria.vehicleLocation.postalCode,
            criteria.vehicleLocation.radius,
            criteria.vehicleLocation.latitude,
            criteria.vehicleLocation.longitude,
            criteria.vehicleLocation.place,
        ].join(',');
        result.vehicleLocation = urlQueryValue;
    }
    const newQueryString = queryString(result);
    dispatch(replace(location.pathname + (newQueryString ? `?${newQueryString}` : '')));
}

/**
 * Builds the search query to be sent to SearchService based on currently selected criteria.
 * cfr https://dev-searchservice.cotw.eu/swagger/index.html for query format.
 *
 */
function searchQuery(state, sorting, pageSize, pageIndex) {
    const searchCriteria = state.carSearch.searchCriteria;
    const buyerCountry = getBuyerCountry(state);

    const extraFacets = [...extraFacetsToRequest];
    if (settings.featureFlags['isNonTransactionalEnabled']) {
        extraFacets.push(...extraFacetsToRequestCommunityNonTransactional);
    }

    const result = {
        query: {},
        //FacetRequest: ["All"],
        FacetRequest: [...Object.values(facetsMapping), ...extraFacets],
    };

    if (sorting) {
        result.Sort = {
            Field: sorting.field,
            Direction: sorting.direction || 'ascending',
            SortType: sorting.byUserPreference ? 'UserPreferences' : 'Field',
        };
    }
    if (pageSize || pageIndex) {
        result.Paging = {
            PageNumber: pageIndex || 0,
            ItemsPerPage: pageSize || 0,
        };
    }

    if (searchCriteria.makesModels.filter(mm => !!mm.make).length > 0) {
        result.query.MakeModels = searchCriteria.makesModels
            .filter(mm => !!mm.make)
            .map(mm => ({
                Make: mm.make,
                Models: mm.models,
            }));
    }

    Object.keys(rangeSearchQueryNameMapping).forEach(k => {
        if (searchCriteria[k] && (searchCriteria[k].from || searchCriteria[k].to)) {
            result.query[rangeSearchQueryNameMapping[k]] = { From: searchCriteria[k].from || null, To: searchCriteria[k].to || null };
        }
    });

    Object.keys(checkboxSearchQueryNameMapping).forEach(k => {
        if (searchCriteria[k] && searchCriteria[k].length > 0) {
            result.query[checkboxSearchQueryNameMapping[k]] = searchCriteria[k];
        }
    });

    delete result.query.WillBeExtended;
    if (searchCriteria.xTimes.length === 1) {
        result.query.WillBeExtended = searchCriteria.xTimes[0]; // criteria is a boolean.
    }
    delete result.query.AssignmentType;
    if (searchCriteria.assignment100pc.length === 1) {
        result.query.AssignmentType = searchCriteria.assignment100pc[0]; // criteria is a boolean.
    }
    delete result.query.IsCarOnStock;
    if (searchCriteria.isCarOnStock.length === 1) {
        result.query.IsCarOnStock = searchCriteria.isCarOnStock[0]; // criteria is a boolean.
    }
    delete result.query.IsDealerCar;
    if (searchCriteria.isLocalDealerCar.length === 1) {
        result.query.IsDealerCar = searchCriteria.isLocalDealerCar[0]; // criteria is a boolean.
        //result.query.CountryCodeDealer = buyerCountry;
    }

    if (searchCriteria.sellers.length) {
        result.query.SellerIds = searchCriteria.sellers.flat(); // flat the array of array of sellerIds (cfr regroupment of sellerid)
    }

    if (searchCriteria.currentAuctionBatchId) {
        result.query.AuctionBatchId = searchCriteria.currentAuctionBatchId;
    }
    if (searchCriteria.auctionIds && searchCriteria.auctionIds.length > 0) {
        result.query.AuctionIds = searchCriteria.auctionIds;
    }
    if (searchCriteria.currentCommunityId) {
        result.query.CommunityId = searchCriteria.currentCommunityId;
    }

    if (searchCriteria.auctionsChannel.includes('myCommunities')) {
        if (!!state.common && state.common.communities.length > 0) {
            result.query.CommunityIds = state.common.communities.map(a => a.id);
        }
    }
    if (searchCriteria.auctionsChannel.includes('nonTransactional')) {
        if (state.common.signUpStatus.hasFullAccess) {
            result.query.NonTransactionalCountries = [state.common.currentUser.country];
        } else {
            result.query.NonTransactionalCountries = ['NOCOUNTRY']; // return no result if not full access.
        }
    }
    if (searchCriteria.auctionsChannel.includes('adesa')) {
        // all auctions except auctions for joined communities & direct sales ones.
        if (searchCriteria.auctionsChannel.includes('nonTransactional')) {
            delete result.query.NonTransactionalCountries;
        } else {
            if (state.common.signUpStatus.hasFullAccess) {
                result.query.NotNonTransactionalCountries = [state.common.currentUser.country];
            }
        }
        if (searchCriteria.auctionsChannel.includes('myCommunities')) {
            delete result.query.CommunityIds;
        } else {
            if (!!state.common && state.common.communities.length > 0) {
                result.query.NotCommunityIds = state.common.communities.map(a => a.id);
            }
        }
    }

    if (!!searchCriteria.vehicleLocation.latitude && !!searchCriteria.vehicleLocation.longitude && !!searchCriteria.vehicleLocation.radius) {
        result.query.Latitude = searchCriteria.vehicleLocation.latitude;
        result.query.Longitude = searchCriteria.vehicleLocation.longitude;
        result.query.Radius = searchCriteria.vehicleLocation.radius;
        result.query.BuyerCountry = buyerCountry;
    }

    return result;
}

/*function count(dispatch, state) {
    const searchUrl = url('findcarv6/search', state.common.currentLanguage);
    fetchJson(searchUrl, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(searchQuery(state.carSearch.searchCriteria)),
    })
        .then(resp => {
            dispatch({ type: NEW_SEARCH_COUNT, result: resp });
        }).catch(e => dispatch(notify({ message: "Error while searching " + e.toString(), status: "error" })));
}*/

function search(dispatch, getState, pageIndex = 0) {
    dispatch({ type: START_SEARCH });
    let state = getState();
    const pageSize = state.carSearch.paging.pageSize;
    const sorting = state.carSearch.sorting;

    // no sorting specified yet, use default based on loggedin/loggedout
    if (!sorting.field) {
        // if (state.common.isUserLoggedIn) {
        //     sorting.byUserPreference = true;
        //     sorting.field = "UserPreference";
        // } else {
        //     sorting.byUserPreference = false;
        //     sorting.field = "CarNameEn";
        // }
        if (state.common.isUserLoggedIn && state.common.currentUser.hasSearchPreferences) {
            sorting.byUserPreference = true;
            sorting.field = 'UserPreference';
        } else {
            sorting.byUserPreference = false;
            sorting.field = 'BatchStartDateForSorting';
            sorting.direction = 'ascending';
        }
    }

    // signalr unregister auctions currently displayed, as they will be replaced by new page.
    if (state.common.isUserLoggedIn) {
        state.carSearch.auctionsList.forEach(a => signalrClient.unregisterForAuctionId(a.auctionId));
    }
    const searchUrl = url('findcarv6/search', state.common.currentLanguage);
    const searchQueryObj = searchQuery(state, sorting, pageSize, pageIndex + 1);

    const extraFacets = [...extraFacetsToRequest];
    if (settings.featureFlags['isNonTransactionalEnabled']) {
        extraFacets.push(...extraFacetsToRequestCommunityNonTransactional);
    }

    // limit facets list to only the visible ones. cfr #3363
    searchQueryObj.FacetRequest = [...state.carSearch.visibleFacets.filter(f => !!facetsMapping[f]).map(f => facetsMapping[f]), ...extraFacets];
    const query = {
        ...searchQueryObj,
        // Additional fields necessary for stats.
        UniqueSearchLogId: state.carSearch.searchGuid,
        SavedSearchId: state.carSearch.selectedSavedSearch ? state.carSearch.selectedSavedSearch.id : null,
        PageUrl: location.toString(),
    };

    return fetchJson(searchUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(query),
    })
        .then(resp => {
            state = getState();
            const currentCountry = getBuyerCountry(state); // either buyer country (logged in user) or country deduced from IP (logged out)
            const myCommunityIds = state.common.communities.map(a => a.id);
            const isUserLoggedIn = state.common.isUserLoggedIn;
            const emptyCriteriaSearch = Object.keys(searchQueryObj.query).length == 0;
            const joinedCommunity = state.common.communities;
            dispatch({
                type: pageIndex === 0 ? NEW_SEARCH_RESULT : NEXT_SEARCH_RESULT,
                result: resp,
                currentLanguage: state.common.currentLanguage,
                contactId: state.common.currentUser.contactId,
                pageIndex,
                currentCountry,
                myCommunityIds,
                isUserLoggedIn,
                emptyCriteriaSearch,
                isJoinedCommunity: joinedCommunity && joinedCommunity.length > 0,
                userHasFullAccess: state.common.signUpStatus.hasFullAccess,
            });

            if (!resp || resp.length === 0) {
                throw 'Empty response from search';
            }

            // register to signalr the new auctions of the current page.
            state = getState();
            if (state.common.isUserLoggedIn) {
                const auctionIds = state.carSearch.auctionsList.map(a => a.auctionId);
                if (signalrClient)
                    // prevent using signalr when not yet connected.
                    signalrClient
                        .registerForAuctions(auctionIds)
                        .catch(e => console.error('SIGNALR Error while registering auctions from search result.', e));
            }
            return Promise.resolve();
        })
        .catch(e => dispatch(notify({ message: 'Error while searching ' + e.toString(), status: 'error' })));
}

function getBuyerCountry(state) {
    return state.common.isUserLoggedIn ? state.common.currentUser.country : state.home.currentCountry;
}

function fetchFacetCounts(criteriaName, dispatch, state) {
    if (!facetsMapping[criteriaName]) return; // skip if no corresponding facet. (cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/658393)

    const searchQueryObj = searchQuery(state);
    searchQueryObj.FacetRequest = [facetsMapping[criteriaName]];
    /*if (criteriaName === "isLocalDealerCar") {
        searchQueryObj.query.CountryCodeDealer = buyerCountry; // restricted to buyerCountry to get only the count of cars from _local_ dealers.
    }*/
    const searchUrl = url('findcarv6/SearchFacetsOnly', state.common.currentLanguage);
    fetchJson(searchUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(searchQueryObj),
    })
        .then(resp => {
            dispatch({ type: ADD_FACET_COUNTS, facets: resp.Facets || resp }); // because of bug in FindCarV6Controller,L:101
        })
        .catch(e => {
            console.error(`Error while retrieving facet ${criteriaName}. Error."${e.toString()}"`);
            dispatch(notify({ message: `Error while retrieving facet ${criteriaName}. Error."${e.toString()}"`, status: 'error' }));
        });
}

/**
 * Called by signalr event.
 *
 */
function otherBidReceived(auctionData, state, dispatch) {
    const auctionId = auctionData.AuctionId;
    const auction = state.carSearch.auctionsList.filter(a => a.auctionId === auctionId)[0];
    if (!auction) return;

    const auctionDataUpdate = signalrAuctionDataToState(auctionData, state);
    dispatch({ type: SIGNALR_BID, auctionDataUpdate, auctionId });
}

// fetch all saved searched of the logged in user.
function fetchSavedSearches(dispatch, state) {
    const searchUrl = url('findcarv6/savedsearches', state.common.currentLanguage);
    return fetchJson(searchUrl).then(list => {
        dispatch({ type: SAVED_SEARCHES_GET, list: list.map(a => savedQueryToState(a)) });
    });
}

// fetch a single saved search by id (used for logged out users)
function fetchSavedSearch(id, dispatch, state) {
    const searchUrl = url(`findcarv6/savedsearch/${id}`, state.common.currentLanguage);
    return fetchJson(searchUrl).then(savedSearch => dispatch({ type: SAVED_SEARCHES_GET, list: [savedQueryToState(savedSearch)] }));
}

function createSavedSearch(savedSearch, dispatch, state) {
    const request = {
        ContactId: state.common.currentUser.contactId,
        Title: savedSearch.name,
        SendMail: savedSearch.sendMail,
        SearchRequest: JSON.stringify({
            searchCriteria: state.carSearch.searchCriteria,
            sorting: state.carSearch.sorting,
            paging: state.carSearch.paging,
        }),
    };
    const searchUrl = url('findcarv6/createsavedsearch', state.common.currentLanguage);
    fetchJson(searchUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
    }).then(id => {
        dispatch({ type: SAVED_SEARCHES_CREATE, savedSearch: { ...savedSearch, id } });
    });
}

function updateSavedSearch(savedSearch, dispatch, state) {
    const request = {
        Id: savedSearch.id,
        ContactId: state.common.currentUser.contactId,
        Title: savedSearch.name,
        SendMail: savedSearch.sendMail,
    };
    const searchUrl = url('findcarv6/updatesavedsearch', state.common.currentLanguage);
    fetchJson(searchUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
    }).then(resp => {
        dispatch({ type: SAVED_SEARCHES_UPDATE, savedSearch });
    });
}

function deleteSavedSearches(ids, dispatch, state) {
    const request = {
        Ids: ids,
        ContactId: state.common.currentUser.contactId,
    };
    const searchUrl = url('findcarv6/deletesavedsearch', state.common.currentLanguage);
    fetchJson(searchUrl, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
    }).then(resp => {
        dispatch({ type: SAVED_SEARCHES_DELETE, ids });
    });
}

function selectSavedSearch(id, dispatch, getState) {
    const state = getState();
    const filtered = state.carSearch.savedSearches.filter(ss => ss.id === id);
    if (filtered.length !== 0) {
        if (state.common.isUserLoggedIn) {
            // send query selection event, to update stats for current user.
            const savedSearch = filtered[0];
            const request = {
                Id: savedSearch.id,
                ContactId: state.common.currentUser.contactId,
                Title: savedSearch.title,
                SendMail: savedSearch.XYZ,
            };
            const searchUrl = url(`findcarv6/UpdateStatSavedSearch/${id}`, state.common.currentLanguage);
            fetchJson(searchUrl, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request),
            });
        }
        dispatch({ type: SAVED_SEARCHES_SELECT, id, searchGuid: uuidv4() });
    }
    // forward to search result, if selected search not found, show full list.
    search(dispatch, getState);
    dispatch(push(`/${state.common.currentLanguage}/findcar`));
}

function fetchCommunity(id, dispatch, getState) {
    const state = getState();
    dispatch({ type: START_FETCHING_COMMUNITY });
    fetchJson(url('communitiesv6/Communities/' + id, state.common.currentLanguage))
        .then(community => {
            if (community) {
                dispatch({ type: COMMUNITY_FECHED, community });
            }
        })
        .catch(e => {
            console.error(e);
            dispatch(notify({ message: 'Error while loading communities ', status: 'error' }));
        });
}

function leaveCommunity(communityId, dispatch, getState) {
    const state = getState();
    fetchJson(url(`CommunitiesV6/leave/${communityId}`, state.common.currentLanguage), {}, 'POST')
        .then(() => {
            dispatch({ type: CLEAR_SEARCH_CRITERIA });
            dispatch({ type: 'COMMUNITIES.INIT' });
            dispatch(push(`/${state.common.currentLanguage}/communities`));
            dispatch(notify({ message: i18n.t('toast.flashing.leftCommunitySuccessfully'), status: 'success' }));
        })
        .catch(e => {
            console.error(e);
            dispatch(notify({ message: 'Error while leaving community ', status: 'error' }));
        });
}

function searchZipCodes(zipCodeToSearch, dispatch, getState) {
    if (zipCodeToSearch) {
        const state = getState();
        const buyerCountry = state.common.currentUser.country;
        return fetchJson(`${settings.metadataServiceUrl}/api/zipcodes/search?country=${buyerCountry}&searchString=${zipCodeToSearch}`)
            .then(response => {
                if (response) {
                    dispatch({ type: SET_LOCATION_METADATA, metadata: response.items });
                }
            })
            .catch(err => {
                console.error(err);
            });
    } else {
        dispatch({ type: SET_LOCATION_METADATA, metadata: [] });
    }
    return Promise.resolve();
}
