import React, { useRef } from 'react';
import { useMyAccountV2Bundle } from '../../hooks/useMyAccountV2Bundle';
import { goToHome, goToLogin } from '../../utils';

// cfr https://louisbarranqueiro.github.io/reapop/
const messageStatusMapping = {
    success: 'success',
    removalSuccess: 'info',
    businessError: 'error',
    internalError: 'error',
    //"": "warning",
    //"": "none",
};

declare global {
    // https://goulet.dev/posts/consuming-web-component-react-typescript/
    namespace JSX {
        interface IntrinsicElements {
            'my-documents-uploader-banner': any;
        }
    }
}

export type UploadDocumentsBannerProps = {
    onNotify: (message: any) => void;
    t: (key: string) => string;
    onRefreshSignUpStatus: () => void;
};

export default function UploadDocumentsBanner({ t, onNotify, onRefreshSignUpStatus }: UploadDocumentsBannerProps) {
    const containerRef = useRef(null);
    const elementRef = useRef(null);

    const messageHandler = evt => {
        const message = evt.detail;
        onNotify({
            message: `${message.message}`,
            status: messageStatusMapping[message.messageType] || 'none',
        });
        if (message.httpErrorCode == 401) {
            // not authorized ==> redirect to home (show login screen too?)
            goToHome();
        }
    };

    const unAuthorizedExceptionHandler = evt => {
        // not authorized ==> redirect to home (show login screen too)
        goToLogin();
    };

    const onDocumentsUploaded = evt => {
        onRefreshSignUpStatus();
    };

    useMyAccountV2Bundle(elementRef, containerRef, messageHandler, unAuthorizedExceptionHandler, onDocumentsUploaded);

    return (
        <div ref={containerRef}>
            <my-documents-uploader-banner ref={elementRef}> </my-documents-uploader-banner>
        </div>
    );
}
