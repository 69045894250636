import React, { ComponentType, MouseEvent, useMemo, useRef } from 'react';

import { Navbar, NavbarItem, NavbarItemGroup, useDesktop, InfoBanner } from '@ambiorix/adesa-web-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faInfoCircle, faUserPlus, prefix } from '@fortawesome/free-solid-svg-icons';
import { cx } from 'class-variance-authority';
import PromoBanner from '../../Common/PromoBanner';
import settings from '../../../appsettings';
import { isSearchPage } from '../../../routing';
import {
    LanguageSelector,
    NavbarItemLanguageSelect,
    isCommunities,
    isMyAccountArchive,
    isMyAccountBidders,
    isMyAccountClaims,
    isMyAccountCommunityOrders,
    isMyAccountDashboard,
    isMyAccountDocuments,
    isMyAccountLogistics,
    isMyAccountOrders,
    isMyAccountSettings,
    isMyBids,
    isMyClaims,
    isMyDocuments,
    isMyFavorites,
    isMyOrders,
    isMySelection,
    isSavedSearches,
    logout,
    isMyAccountHelp,
} from './common';
import LoginButtonAndModal from './LoginButtonUsername';
import { AdesaButton } from '../../Common/Button';
import ImpersonateBanner from '../../Common/ImpersonateBanner';
import { useHistory } from '../../../hooks/useHistory';
import styles from './index.module.scss';
import { AuctionBatches, CarSearch, Communities, Home, MyBids, MySelectionView, RegisterPage } from '../../../lazy-router';
import WebSocketErrorModal from '../../../components/Common/WebSocketErrorModal';
import UploadDocumentsBanner from '../../Common/UploadDocumentsBanner';
import useContentSize from '../../../hooks/useContentSize';
import BlockedBanner, { BIDDING_NOT_ALLOWED } from '../../Common/BlockedBanner';
import { CompanyOrContactBlocked } from 'src/state/Login';
import { goToMyCotwAccountProfile } from '../../../utils';

const COMPANY_BLOCKED_REASONID_NOT_ALLOWED_TO_BID = 6;

type TopNavigationProps = {
    communities: Array<any>; // TODO: [jordy] add proper typing
    currentLanguage: string;
    currentUser: any; // TODO: [jordy] add proper typing
    currentUserHasEuCatalogueAccess: boolean;
    isUserLoggedIn: boolean;
    isWebSocketInError: boolean;
    loading: boolean;
    match: { url: string };
    onGoToPage: (path: string) => void;
    onLogin: (username: string, password: string, remember: boolean) => void;
    onPreLogin: (username: string | null, remember?: boolean) => void;
    onNotify: (message: any) => void;
    t: (key: string) => string;
    usernameLogin: string;
    promoDetails: any;
    companyOrContactBlocked?: CompanyOrContactBlocked;
    companyBlockedReasonId?: number;
    signUpStatus?: any;
    onSendInactivityEmailVerification: () => void;
    onRefreshSignUpStatus: () => void;
    showBusinessTypeBanner: boolean;
};

export const TopNavigation = ({
    communities,
    currentLanguage,
    currentUser,
    currentUserHasEuCatalogueAccess,
    isUserLoggedIn,
    isWebSocketInError,
    loading,
    match,
    onGoToPage,
    onLogin,
    onPreLogin,
    onNotify,
    t,
    usernameLogin,
    promoDetails,
    companyOrContactBlocked,
    companyBlockedReasonId,
    signUpStatus,
    onSendInactivityEmailVerification,
    onRefreshSignUpStatus,
    showBusinessTypeBanner,
}: TopNavigationProps) => {
    const isDesktop = useDesktop();
    const { pathname } = useHistory();

    const isHomePage = /\/home\b/i.test(pathname);
    const isAuctionBatchSearch = isSearchPage() && /\bauctionBatchId=/i.test(location.search);
    const isCommunitySearch = isSearchPage() && /\bcommunityId=/i.test(location.search);
    const _isSearchPage = isSearchPage() && !isAuctionBatchSearch && !isCommunitySearch;
    const isAuctionsPage = /\/auctions\b/i.test(pathname) || isAuctionBatchSearch;
    const isCommunityPage = isCommunities(pathname) || isCommunitySearch;
    const wordPressEnabled = !!settings.featureFlags['IsNewFooterEnabled'];

    const getUrl = (path: string) => {
        return `${settings.carsiteBaseUrl}/${currentLanguage}/${path}`;
    };

    const onLinkClicked = (path: string) => (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        onGoToPage(path);
    };

    const urlHandler = (path: string, prefetch?: () => Promise<{ default: ComponentType<any> }>) => ({
        href: getUrl(path),
        onClick: onLinkClicked(path),
        onMouseOver: prefetch,
    });

    const goToRegistrationForm = () => (document.location = `/${currentLanguage}/register`);

    const myAccountMenu = isUserLoggedIn && (
        <NavbarItemGroup className="uitest-myaccount-link" icon={<FontAwesomeIcon icon={faGear} fixedWidth />} label={t('navigation.top.myAccount')}>
            <NavbarItem selected={isMyAccountDashboard(pathname)} {...urlHandler('my-account/dashboard')}>
                {t('navigation.top.dashboard')}
            </NavbarItem>
            <NavbarItem selected={isMyBids(pathname)} {...urlHandler('myaccount/biddingdashboard', MyBids.load)}>
                {t('navigation.top.myBids')}
            </NavbarItem>
            <NavbarItem selected={isMyFavorites(pathname)} {...urlHandler('myaccount/biddingdashboard#following', MyBids.load)}>
                {t('navigation.top.favorites')}
            </NavbarItem>
            <NavbarItem selected={isSavedSearches(pathname)} {...urlHandler('findcar/saved')}>
                {t('navigation.top.savedSearches')}
            </NavbarItem>
            <NavbarItem selected={isMySelection(pathname)} {...urlHandler('my-recommendations', MySelectionView.load)}>
                {t('navigation.top.mySelection')}
            </NavbarItem>
            <NavbarItem selected={isMyOrders(pathname)} {...urlHandler('my-account/my-orders')}>
                {t('navigation.top.orders')}
            </NavbarItem>
            <NavbarItem selected={isMyDocuments(pathname)} {...urlHandler('my-account/my-documents')}>
                {t('navigation.top.documents')}
            </NavbarItem>

            {currentUserHasEuCatalogueAccess && currentUser.isMainContact && (
                <NavbarItem selected={isMyAccountBidders(pathname)} {...urlHandler('my-account/bidders')}>
                    {t('navigation.top.bidders')}
                </NavbarItem>
            )}
            <NavbarItem selected={isMyAccountSettings(pathname)} {...urlHandler('my-account/settings')}>
                {t('navigation.top.settings')}
            </NavbarItem>
            <NavbarItem selected={isMyAccountHelp(pathname)} {...urlHandler('my-account/help')}>
                {t('navigation.top.help')}
            </NavbarItem>
            <NavbarItem selected={isMyClaims(pathname)} {...urlHandler('my-account/my-claims')}>
                {t('navigation.top.claims')}
            </NavbarItem>
            {isDesktop && (
                <NavbarItem data-testid="logout-link" onClick={logout(currentLanguage)}>
                    {t('navigation.top.logOut')}
                </NavbarItem>
            )}
        </NavbarItemGroup>
    );

    const navItemsConnect = (
        <>
            <LoginButtonAndModal
                currentLanguage={currentLanguage}
                t={t}
                usernameLogin={usernameLogin}
                onPreLogin={onPreLogin}
                onLogin={onLogin}
                loading={loading}
                companyOrContactBlocked={companyOrContactBlocked}
            />
            <AdesaButton
                id="registerButton"
                fullWidth={false}
                size="sm"
                suffix={<FontAwesomeIcon icon={faUserPlus} fixedWidth />}
                onClick={goToRegistrationForm}>
                {t('login.button.register')}
            </AdesaButton>
        </>
    );

    const navItemsTopRight =
        isUserLoggedIn && location.hash !== '#login' ? (
            <span className={cx('username', styles.greeting)}>
                {t('navigation.top.hello')} {currentUser.firstName}
            </span>
        ) : (
            <>
                {navItemsConnect}
                <LanguageSelector currentLanguage={currentLanguage} />
            </>
        );

    const logo = isDesktop ? (
        <img height={34} src="/v6/images/openlane-logo.svg" title="OPENLANE" alt="OPENLANE" />
    ) : (
        <img height={17} src="/v6/images/openlane-logo-mobile.svg" title="OPENLANE" alt="OPENLANE" />
    );

    const banners = useMemo(() => {
        const list = [<React.Fragment key="emptyFragment"></React.Fragment>];
        if (currentUser?.isImpersonated) {
            list.push(
                <ImpersonateBanner
                    t={t}
                    key="impersonateBanner"
                    className="fixed-banner"
                    currentUser={currentUser}
                    onLogout={logout(currentLanguage)}
                />,
            );
        }
        if (!!companyOrContactBlocked) {
            list.push(
                <BlockedBanner
                    t={t}
                    currentLanguage={currentLanguage}
                    key="companyOrContactBlocked"
                    blockedType={companyOrContactBlocked}
                    companyBlockedReasonId={companyBlockedReasonId}
                />,
            );
        }
        if (signUpStatus.isBiddingAllowed === false) {
            list.push(
                <BlockedBanner
                    t={t}
                    currentLanguage={currentLanguage}
                    key="biddingNotAllowed"
                    blockedType={BIDDING_NOT_ALLOWED}
                    companyBlockedReasonId={
                        !signUpStatus.isBiddingAllowedForContact ? COMPANY_BLOCKED_REASONID_NOT_ALLOWED_TO_BID : signUpStatus.BiddingBlockedReasonId
                    }
                    isCommunityMember={communities.length > 0}
                    dismissable
                    isMainContact={signUpStatus.isMainContact}
                    mainContactName={signUpStatus.mainContactName}
                    contactEmail={signUpStatus.contactEmail}
                    onSendInactivityEmailVerification={onSendInactivityEmailVerification}
                />,
            );
        }
        list.push(<UploadDocumentsBanner onNotify={onNotify} t={t} onRefreshSignUpStatus={onRefreshSignUpStatus} key="uploadDocumentsBanner" />); // show/hide status is managed by component itself (web component)

        if (currentUser.isMainContact && isUserLoggedIn && promoDetails) {
            list.push(<PromoBanner t={t} key="promoBanner" promoDetails={promoDetails} currentLanguage={currentLanguage} />);
        }

        if (showBusinessTypeBanner) {
            list.push(
                <InfoBanner
                    variant="info"
                    style="ghost"
                    title={t('banner.status.title.defineYourBusinessType')}
                    actionProps={{ onClick: () => goToMyCotwAccountProfile(currentLanguage as any, true) }}
                    actionText={t('common.action.defineYourBusinessType')}
                    description={t('banner.status.businessType.description')}
                    showCloseIcon={false}
                    titleIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                />,
            );
        }

        return list;
    }, [
        currentUser,
        currentUser?.isImpersonated,
        isWebSocketInError,
        promoDetails,
        isUserLoggedIn,
        companyOrContactBlocked,
        companyBlockedReasonId,
        signUpStatus,
        communities,
    ]);
    const bannerContainerRef = useRef(null);
    const { height: bannersContainerHeight } = useContentSize(bannerContainerRef); // apply padding only if there is a content (cfr doc uploader banner)
    return (
        <>
            {isWebSocketInError && <WebSocketErrorModal t={t} key="webSocketErrorBanner" />}
            <div ref={bannerContainerRef} className={cx(!!bannersContainerHeight && styles.bannerContainer)}>
                {banners}
            </div>
            <Navbar
                className={cx('rc-top-navigation', styles.navbar)}
                logo={logo}
                logoProps={{
                    className: `${styles.navbarLogo} navbar-brand uitest-logo-link`,
                    href: '/',
                    title: t('common.brand.carsOnTheWeb'),
                }}
                headerProps={{
                    closeText: t('navigation.top.close'),
                    disconnectText: t('navigation.top.disconnect'),
                    greetingText: t('navigation.top.greeting'),
                    onDisconnect: logout(currentLanguage),
                    openText: t('navigation.top.open'),
                    user: isUserLoggedIn ? currentUser : undefined,
                }}
                variant="2-rows"
                slots={{
                    topRight: navItemsTopRight,
                    bottomRight: [
                        myAccountMenu,
                        !isDesktop && <NavbarItemLanguageSelect currentLanguage={currentLanguage} />,
                        !isDesktop && !isUserLoggedIn && <li className={styles.connectButtons}>{navItemsConnect}</li>,
                    ],
                }}>
                <NavbarItem className="uitest-home-link" selected={isHomePage} {...urlHandler('home', Home.load)}>
                    {t('navigation.top.home')}
                </NavbarItem>
                <NavbarItem className="uitest-search-link" selected={_isSearchPage} {...urlHandler('findcar/advanced', CarSearch.load)}>
                    {t('navigation.top.findCars')}
                </NavbarItem>
                {isUserLoggedIn && communities.length > 0 && (
                    <NavbarItem className="uitest-communities-link" selected={isCommunityPage} {...urlHandler('communities', Communities.load)}>
                        {t('navigation.top.communities')}
                    </NavbarItem>
                )}
                <NavbarItem className="uitest-auctions-link" selected={isAuctionsPage} {...urlHandler('auctions', AuctionBatches.load)}>
                    {t('navigation.top.auctions')}
                </NavbarItem>
                <NavbarItem
                    className="uitest-sellcars-link"
                    href={
                        wordPressEnabled ? `https://info.openlane.eu/${currentLanguage}` : `https://sellers.openlane.eu/${currentLanguage}/sell-cars/`
                    }>
                    {wordPressEnabled ? t('navigation.top.infoCentre') : t('navigation.top.sellCars')}
                </NavbarItem>
            </Navbar>
            {/* Exposed the login compenent outside of the nav so that showLoginDialog query selector can access it because on mobile its not working */}

            {!isDesktop && (
                <div className={styles.loginModalstyle}>
                    <LoginButtonAndModal
                        currentLanguage={currentLanguage}
                        t={t}
                        usernameLogin={usernameLogin}
                        onPreLogin={onPreLogin}
                        onLogin={onLogin}
                        loading={loading}
                        companyOrContactBlocked={companyOrContactBlocked}
                    />
                </div>
            )}
        </>
    );
};
