import settings from '../../appsettings';
import { auctionToState, isOptimoSelected, paymentStatusFromString, paymentStatusFromCode, isFinSelected } from './mappings';
import {
    fetchJson,
    queryString,
    scrollToTop,
    scrollToBottom,
    postForm,
    logAnalytics,
    goToMyCotwForConfirmation,
    goToHome,
    goToSearch,
    goToMyPurchases,
    parsedQueryString,
} from '../../utils';
import { notify } from 'reapop';
import { ADDRESS_TYPE, PAYMENT_STATUS } from '../cotw';
import { replace, push, goBack } from 'connected-react-router';
import i18n from '../../i18n';
import {
    INIT,
    SET_COUNTRIES,
    SELECT_DESTINATION_COUNTRY,
    SELECT_TRANSPORT_MODE,
    SELECT_DELIVERY_ADDRESS,
    EDIT_DELIVERY_ADDRESS,
    ADD_DELIVERY_ADDRESS,
    SELECT_FINANCIAL_SERVICE,
    SELECT_ADDITIONAL_SERVICES,
    SELECT_NON_EU_OPTION,
    REMOVE_DELIVERY_ADDRESS,
    FETCHED_ADDRESSES,
    FETCHED_DELIVERY_OPTIONS,
    FETCHED_ADDITIONAL_SERVICES,
    FETCHED_DOCUMENT,
    SHOW_OVERVIEW,
    SHOW_CONFIRMED_VIEW,
    GO_BACK,
    SAVED_INVOICING_ADDRESS,
    CHANGE_NON_EU_FIELDS,
    CONFIRMATION_BUSY,
    FETCHED_BUYER_PROMOS,
    SELECT_BUYER_PROMO,
    SET_SELECTED_OPTIONS,
    SET_PAYMENT_STATUS,
    REDIRECTING_TO_OGONE,
    SELECT_PAYMENT_METHOD_UK,
    SET_SELLER,
    SET_NOT_ALLOWED_DESTINATION_COUNTRY,
    SET_NOT_ALLOWED_DELIVERY_ADDRESS_COUNTRY,
    SET_REMAINING_PAYMENT_DETAILS,
} from './actions';

export { orderCompletionReducer } from './reducer';

function url(urlSuffix, currentLanguage) {
    return settings.carsiteBaseUrl + '/' + currentLanguage + '/' + urlSuffix;
}

const urlBackStorageKey = 'ADESA:OrderCompletion:UrlBack';

export const orderCompletionMapStateToProps = state => ({
    ...state.orderCompletion,
});

const logOrderCompletionAnalytics = (action, state, message, fromBuyerApp = false) =>
    logAnalytics(
        'OrderCompletion',
        action,
        state.common.currentLanguage,
        `${state.orderCompletion.auctionToOrder.auctionId || ''}`,
        message,
        fromBuyerApp,
    );

export const orderCompletionActionsCreator = /*(dispatch, getState) =>*/ {
    onInit: auctionId => (dispatch, getState) => {
        init(auctionId, dispatch, getState).then(() => logOrderCompletionAnalytics('INIT', getState()));
        const queryParams = parsedQueryString(window.location.search);
        if (queryParams.from) {
            sessionStorage.setItem(urlBackStorageKey, `/${getState().common.currentLanguage}${queryParams.from}`);
        } else {
            sessionStorage.removeItem(urlBackStorageKey);
        }
    },
    onInitAfterPayment: auctionId => (dispatch, getState) => {
        initAfterPrePayment(auctionId, dispatch, getState).then(() => logOrderCompletionAnalytics('RETURN_FROM_PAY', getState()));
    },
    onSelectDestinationCountry: destinationCountry => (dispatch, getState) => {
        dispatch({ type: SELECT_DESTINATION_COUNTRY, destinationCountry });
        logOrderCompletionAnalytics('SELECT_DEST_COUNTRY', getState(), `selected country [${destinationCountry}]`);
    },
    onSelectDeliveryAddress: selectedId => (dispatch, getState) => {
        dispatch({ type: SELECT_DELIVERY_ADDRESS, selectedId });
        fetchDeliveryOptions(dispatch, getState);
        logOrderCompletionAnalytics('SELECT_DELIVERY_ADDRESS', getState(), `new address [${selectedId}]`);
    },
    onSelectTransportMode: selectedId => (dispatch, getState) => {
        dispatch({ type: SELECT_TRANSPORT_MODE, selectedId });
        logOrderCompletionAnalytics('SELECT_TRANSPORT_MODE', getState(), `transport mode [${selectedId}]`);
    },
    onSelectFinancialService: selectedId => (dispatch, getState) => {
        dispatch({ type: SELECT_FINANCIAL_SERVICE, selectedId });
        logOrderCompletionAnalytics('SELECT_FINANCIAL_SERVICE', getState(), `financial service [${selectedId}]`);
    },
    onSelectDeselectAdditionalService: selectedId => (dispatch, getState) => {
        dispatch({ type: SELECT_ADDITIONAL_SERVICES, selectedId });
        logOrderCompletionAnalytics('TOGGLE_ADDITIONAL_SERVICE', getState(), `additional service [${selectedId}]`);
    },
    onSelectDeselectBuyerPromo: selectedId => (dispatch, getState) => {
        dispatch({ type: SELECT_BUYER_PROMO, selectedId });
        logOrderCompletionAnalytics('TOGGLE_BUYER_PROMO', getState(), `buyer promo [${selectedId}]`);
    },
    onSelectDeselectNonEuOption: selectedId => (dispatch, getState) => {
        dispatch({ type: SELECT_NON_EU_OPTION, selectedId });
        logOrderCompletionAnalytics('TOGGLE_NON_EU_OPTION', getState(), `non eu option [${selectedId}]`);
    },
    onRemoveDeliveryAddress: id => (dispatch, getState) => {
        removeDeliveryAddress(id, dispatch, getState);
        logOrderCompletionAnalytics('REMOVE_DELIVERY_ADDRESS', getState(), `removed address [${id}]`);
    },
    onAddDeliveryAddress: address => (dispatch, getState) => {
        addDeliveryAddress(address, dispatch, getState);
        logOrderCompletionAnalytics('ADD_DELIVERY_ADDRESS', getState(), `new address [${JSON.stringify(address)}]`);
    },
    onSetDeliveryAddressAsDefault: address => (dispatch, getState) => {
        setDeliveryAddressAsDefault(address, dispatch, getState);
        logOrderCompletionAnalytics('SET_DEFAULT_DELIVERY_ADDRESS', getState(), `address [${address.id}]`);
    },
    onSaveInvoicingAddress: address => (dispatch, getState) => {
        saveInvoicingAddress(address, dispatch, getState);
        logOrderCompletionAnalytics('ADD_INVOICE_ADDRESS', getState(), `new address [${JSON.stringify(address)}]`);
    },
    onChangeNonEuFields: (country, city) => (dispatch, getState) => {
        dispatch({ type: CHANGE_NON_EU_FIELDS, country, city });
        if (country || city) {
            logOrderCompletionAnalytics('ADD_NON_EU_FIELDS', getState(), `country [${country}] city [${city}]`);
        }
    },
    onShowOverview: () => (dispatch, getState) => {
        dispatch({ type: SHOW_OVERVIEW });
        scrollToTop();
        logOrderCompletionAnalytics('GO_TO_OVERVIEW', getState());
    },
    onOverviewNext: () => (dispatch, getState) => {
        doConfirmOrPrePayment(dispatch, getState);
    },
    onBack: () => (dispatch, getState) => {
        const state = getState();
        logOrderCompletionAnalytics('GO_BACK', getState());
        if (state.orderCompletion.currentStep === 0 || state.orderCompletion.options.isNonTransactionalCompletion) {
            //dispatch(goBack());
            window.history.back();
        } else {
            dispatch({ type: GO_BACK });
        }
    },

    // "what's next" actions : go to home page (default behaviour) or go back to previous activity page following saved url (myorders, ....)
    onGoToHome: () => (dispatch, getState) => {
        notifyMobileAppCompletionDone();
        const urlBack = sessionStorage.getItem(urlBackStorageKey);
        if (urlBack) {
            window.location.href = urlBack;
        } else {
            goToHome();
        }
        logOrderCompletionAnalytics('NEXT_GO_HOME', getState());
    },
    onGoToNextConfirmation: auctionId => (dispatch, getState) => {
        goToMyCotwForConfirmation(auctionId);
        logOrderCompletionAnalytics('NEXT_GO_NEXT_CONFIRMATION', getState(), `next auction id: [${auctionId}]`);
    },
    onGoToSearch: () => (dispatch, getState) => {
        notifyMobileAppCompletionDone();
        goToSearch();
        logOrderCompletionAnalytics('NEXT_GO_SEARCH', getState());
    },
    onGoToMyPurchases: () => (dispatch, getState) => {
        notifyMobileAppCompletionDone();
        goToMyPurchases();
        logOrderCompletionAnalytics('NEXT_GO_MY_PURCHASES', getState());
    },
    onGetInvoiceDoc: () => (dispatch, getState) => {
        // invoice document generated asynchronously.
        const state = getState();
        const getDocUrl = url(
            `myaccount/ordercompletion/GetInvoice?auctionId=${state.orderCompletion.auctionToOrder.auctionId}`,
            state.common.currentLanguage,
        );
        fetchJson(getDocUrl).then(resp => {
            if (resp.isDocumentReady) {
                window.open(resp.invoiceUrl);
            } else {
                dispatch(
                    notify({
                        message: i18n.t('toast.flashing.invoiceDocumentNotReadyYet'),
                        status: 'warning',
                    }),
                );
            }
        });
    },
    onSelectPaymentMethod: selectedId => (dispatch, getState) => {
        dispatch({ type: SELECT_PAYMENT_METHOD_UK, selectedId });
        logOrderCompletionAnalytics('TOGGLE_PAYMENT_METHOD_UK', getState(), `payment method uk [${selectedId}]`);
    },
    onGetOrderDocuments: (auctionId, documentType) => (dispatch, getState) => {
        const state = getState();
        if (state.orderCompletion.auctionToOrder.auctionId !== auctionId) return;
        if (state.orderCompletion.currentStep !== 2) return;

        fetchDocument(auctionId, documentType, dispatch, state);
    },

    // signalr event received when order is completed in async mode.
    onOrderCompleted: auctionId => (dispatch, getState) => {
        const isAsyncOrderCompletionEnabled = !!settings.featureFlags['IsAsyncOrderCompletionEnabled'];
        if (!isAsyncOrderCompletionEnabled) return;

        const state = getState();
        if (state.orderCompletion.auctionToOrder.auctionId !== auctionId) return;
        if (state.orderCompletion.currentStep !== 2) return;
        fetchSavedOptions(auctionId, dispatch, state);
        //Need to call that method to fetch payment deatils;
        fetchRemainingPaymentDetails(auctionId, dispatch, state);
    },
};

function checkError(errMsg, dispatch, getState) {
    console.error('ERROR during fetch of data for order completion: ', errMsg);
    const state = getState();
    if (errMsg.status === 401) {
        // unauthorized... go back to myaccount or home if not logged in.
        dispatch(notify({ message: 'Not authorized to view this auction!', status: 'error' }));
        if (state.common.isUserLoggedIn) {
            window.location.href = `/${state.common.currentLanguage}/myaccount/purchases`;
        } else {
            dispatch(push(`/${state.common.currentLanguage}/home`));
        }
    } else {
        dispatch(notify({ message: 'Error while getting auction to complete.', status: 'error' }));
    }
}

function checkForNotAllowedDestinationCountry(dispatch, getState) {
    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/603685
    const state = getState();
    const isUkOrder = state.orderCompletion.options.isUkOrderCompletion;

    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/608703
    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/647123
    const carSellerCountry = state.orderCompletion.auctionToOrder.sellerCountryId;
    const isLocal = carSellerCountry === state.orderCompletion.auctionToOrder.buyerCountry;

    if (!isUkOrder && !!carSellerCountry && !isLocal) {
        dispatch({ type: SET_NOT_ALLOWED_DESTINATION_COUNTRY, country: carSellerCountry });
        dispatch({ type: SET_NOT_ALLOWED_DELIVERY_ADDRESS_COUNTRY, country: carSellerCountry });
    }
}

/**
 * Initialize the redux state for an auction to be confirmed.
 * Starts the order confirmation process.
 */
function init(auctionId, dispatch, getState) {
    return Promise.all([
        fetchAuctionToComplete(auctionId, dispatch, getState),
        fetchAddresses(dispatch, getState),
        fetchCountries(dispatch, getState(), auctionId), // countries are necessary to be loaded here to calculate flag "isEu"
    ])
        .then(([_, selectedAddressId]) => {
            checkForNotAllowedDestinationCountry(dispatch, getState);
            if (selectedAddressId) {
                const state = getState();
                const addresses = state.orderCompletion.options.addresses;
                const notAllowedDeliveryAddressCountry = state.orderCompletion.options.notAllowedDeliveryAddressCountry;

                const selectedAddress = addresses.find(address => address.id === selectedAddressId);
                const mainAddress = addresses.find(ad => ad.addressTypeId === ADDRESS_TYPE.MAIN);
                const isAddressPreSelectionPossible = selectedAddress && selectedAddress.countryId !== notAllowedDeliveryAddressCountry;
                const addressToPreSelect = isAddressPreSelectionPossible ? selectedAddressId : mainAddress.id;

                dispatch({ type: SELECT_DELIVERY_ADDRESS, selectedId: addressToPreSelect });
                fetchDeliveryOptions(dispatch, getState); // requires that the address is selected
            }
            fetchAdditionalServices(auctionId, dispatch, getState());
            fetchBuyerPromos(dispatch, getState());
            const state = getState();
            if (state.orderCompletion.currentStep === 2) {
                showConfirmedView(dispatch, state);
            }
        })
        .catch(errMsg => checkError(errMsg, dispatch, getState));
}

/**
 * This routine is called when coming from the pre-payment page (ogone/ingenico)
 *    - re-hydrate redux state
 *    - continue confirmation process. (after checking payment status)
 *
 */
function initAfterPrePayment(auctionId, dispatch, getState) {
    const isAsyncOrderCompletionEnabled = !!settings.featureFlags['IsAsyncOrderCompletionEnabled'];

    return Promise.all([
        fetchAuctionToComplete(auctionId, dispatch, getState, true),
        fetchAddresses(dispatch, getState), // needs the invoicing address to be sent to the confirm endpoint.
        fetchCountries(dispatch, getState(), auctionId), // countries are necessary to be loaded here to calculate flag "isEu"
    ])
        .then(() => {
            Promise.all([
                isAsyncOrderCompletionEnabled ? Promise.resolve() : fetchSavedOptions(auctionId, dispatch, getState()), //--
                fetchSeller(dispatch, getState, auctionId), // to fetch non-transactional order seller details
            ]).then(() => {
                dispatch({
                    type: SET_PAYMENT_STATUS,
                    status: paymentStatusFromString(window.OGONE_paymentStatus),
                    showWaitingModal: true,
                });
                scrollToBottom();
                const state = getState();
                switch (state.orderCompletion.paymentDetails.paymentStatus) {
                    case PAYMENT_STATUS.SUCCESS:
                        confirmAfterPrePayment(dispatch, getState);
                        break;
                    case PAYMENT_STATUS.PENDING:
                        checkPaymentStatus(dispatch, getState);
                        break;
                    // in case of error => modal with error is displayed (based on new payementStatus prop)
                }
            });
        })
        .catch(errMsg => checkError(errMsg, dispatch, getState));
}

/**
 * Confirmation is always done after successful payment!
 * And payment is only done when Optimo(Fin) has been selected.
 *
 */
function doConfirmOrPrePayment(dispatch, getState) {
    const state = getState().orderCompletion;
    const isAsyncOrderCompletionEnabled = !!settings.featureFlags['IsAsyncOrderCompletionEnabled'];
    dispatch({ type: CONFIRMATION_BUSY, busy: true });
    const doFirst = isAsyncOrderCompletionEnabled ? Promise.resolve() : saveSelectedOptions(dispatch, getState);
    doFirst
        .then(() => {
            // if pre-payment required, go to ogone otherwise confirm directly.
            if (state.options.isNonTransactionalCompletion || isOptimoSelected(state.options) || isFinSelected(state.options)) {
                logOrderCompletionAnalytics('PAY', getState());
                return goToOgone(dispatch, getState());
            } else {
                logOrderCompletionAnalytics('CONFIRM', getState());
                return confirm(dispatch, getState);
            }
        })
        .then(() => {
            if (!isOptimoSelected(state.options) && !isFinSelected(state.options)) {
                logOrderCompletionAnalytics('CONFIRM_SUCCESS', getState(), undefined, !!window.ReactNativeWebView);
                return showConfirmedView(dispatch, getState());
            }
            return Promise.resolve();
        })
        .catch(err => {
            dispatch({ type: CONFIRMATION_BUSY, busy: false });
            console.error(err);
            logOrderCompletionAnalytics('CONFIRM_ERROR', getState(), undefined, !!window.ReactNativeWebView);
            dispatch(notify({ message: 'Error while doing confirmation', status: 'error' }));
        });
}

function confirm(dispatch, getState) {
    const state = getState();
    const order = state.orderCompletion;
    const auctionId = order.auctionToOrder.auctionId;
    const isAsyncOrderCompletionEnabled = !!settings.featureFlags['IsAsyncOrderCompletionEnabled'];

    // save data...
    const confirmUrl = url('myaccount/ordercompletion/Confirm?', state.common.currentLanguage);
    return fetchJson(confirmUrl + queryString({ auctionId }), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(saveConfirmRequest(order)),
    }).then(resp => {
        if (isAsyncOrderCompletionEnabled && !resp) {
            dispatch(notify({ message: i18n.t('toast.flashing.completionAlreadyRequested'), status: 'warning' }));
        }
    });
}

/**
 * Fetch post payments details (BIC, IBAN, amount remaining to pay...) to be displayed on final step 3.
 * @param {*} dispatch
 * @param {*} state
 */
function showConfirmedView(dispatch, state) {
    const isAsyncOrderCompletionEnabled = !!settings.featureFlags['IsAsyncOrderCompletionEnabled'];

    const order = state.orderCompletion;
    const auctionId = order.auctionToOrder.auctionId;
    const paymentsDetailsUrl = url('myaccount/ordercompletion/GetPaymentInfo?' + queryString({ auctionId }), state.common.currentLanguage);
    const nextStepUrl = url('myaccount/ordercompletion/NextAuctionToComplete', state.common.currentLanguage);

    if (isAsyncOrderCompletionEnabled) {
        return fetchJson(nextStepUrl).then(nextStepsDetails => {
            dispatch({ type: CONFIRMATION_BUSY, busy: false });
            dispatch({ type: SHOW_CONFIRMED_VIEW, nextStepsDetails });
            scrollToTop();
        });
    } else {
        return Promise.all([
            fetchJson(paymentsDetailsUrl).catch(() => ({ CarLocationDetail: {}, BankAccountDetails: {} })), // display blank if issue cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/670438
            fetchJson(nextStepUrl),
        ]).then(([paymentDetails, nextStepsDetails]) => {
            dispatch({ type: CONFIRMATION_BUSY, busy: false });
            dispatch({ type: SET_REMAINING_PAYMENT_DETAILS, paymentDetails });
            dispatch({ type: SHOW_CONFIRMED_VIEW, nextStepsDetails });
            scrollToTop();
        });
    }
}

function fetchBuyerPromos(dispatch, state) {
    var companyId = state.common.currentUser.companyId;
    return fetchJson(url(`PromotionsService/GetActiveByCompanyId?${queryString({ companyId })}`, state.common.currentLanguage)).then(promos => {
        let data = promos.map(p => ({
            PromotionId: p.PromoId,
            PromotionType: 'Promo',
            Amount: p.Amount,
            ValidUntil: p.DateValidTo,
            ClaimPromoId: p.ClaimId,
        }));
        dispatch({ type: FETCHED_BUYER_PROMOS, data });
    });
}

function goToOgone(dispatch, state) {
    const order = state.orderCompletion;
    const auctionId = order.auctionToOrder.auctionId;
    const getOgoneRequestUrl = url('myaccount/ordercompletion/GetOgoneRequest?' + queryString({ auctionId }), state.common.currentLanguage);
    dispatch({ type: REDIRECTING_TO_OGONE });
    return fetchJson(getOgoneRequestUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(saveConfirmRequest(order)),
    }).then(ogoneReq => {
        // post form to ogone => which should redirect to ogone payment page.
        postForm(ogoneReq.OgonePostUrl, ogoneReq.OgoneRequest);
    });
}

/**
 * Periodically checks payementStatus if still pending.
 * @param {*} dispatch
 * @param {*} getState
 */
function checkPaymentStatus(dispatch, getState) {
    const state = getState();
    const auctionId = state.orderCompletion.auctionToOrder.auctionId;
    const checkPaymenttUrl = url('myaccount/ordercompletion/CheckPaymentStatus?' + queryString({ auctionId }), state.common.currentLanguage);
    return fetchJson(checkPaymenttUrl).then(resp => {
        dispatch({ type: SET_PAYMENT_STATUS, ...paymentStatusFromCode(resp), showWaitingModal: true });
        const updatedState = getState();
        switch (updatedState.orderCompletion.paymentDetails.paymentStatus) {
            case PAYMENT_STATUS.SUCCESS:
                confirmAfterPrePayment(dispatch, getState);
                break;
            case PAYMENT_STATUS.PENDING:
                // check again payement status in 2 s.
                setTimeout(() => checkPaymentStatus(dispatch, getState), 2000);
                break;
            // in case of error => modal with error is displayed (based on new payementStatus prop)
        }
    });
}

/**
 * Confirmation after the pre-payment.
 */
function confirmAfterPrePayment(dispatch, getState) {
    const state = getState();
    const isAsyncOrderCompletionEnabled = !!settings.featureFlags['IsAsyncOrderCompletionEnabled'];
    if (isAsyncOrderCompletionEnabled) {
        return showConfirmedView(dispatch, state); // goes to "confirmed" step.
    } else {
        dispatch({ type: CONFIRMATION_BUSY, busy: true });
        return confirm(dispatch, getState)
            .then(() => {
                const state = getState();
                logOrderCompletionAnalytics('CONFIRM_SUCCESS', getState(), undefined, !!window.ReactNativeWebView);
                return showConfirmedView(dispatch, state); // goes to "confirmed" step.
            })
            .catch(err => {
                console.error(err);
                logOrderCompletionAnalytics('CONFIRM_ERROR', getState(), undefined, !!window.ReactNativeWebView);
                dispatch({ type: CONFIRMATION_BUSY, busy: false });
                dispatch(notify({ message: 'Error while doing confirmation', status: 'error' }));
            });
    }
}

function fetchCountries(dispatch, state, auctionId) {
    return fetchJson(url('myaccount/ordercompletion/countries?' + queryString({ auctionId }), state.common.currentLanguage)).then(countries =>
        dispatch({ type: SET_COUNTRIES, countries }),
    );
}

function fetchSeller(dispatch, getState, auctionId) {
    const state = getState();
    const isNonTransactional = state.orderCompletion.options.isNonTransactionalCompletion;
    return isNonTransactional
        ? fetchJson(url('ordersv6/NonTransactionalAuctionSeller?' + queryString({ auctionId }), state.common.currentLanguage)).then(seller =>
              dispatch({ type: SET_SELLER, seller }),
          )
        : Promise.resolve();
}

const auctionToCompleteStorageKey = 'ADESA:OrderCompletion:AuctionToComplete';

/**
 * After confirmation the auctionToComplete is not more accessible.
 * So take it from localStorage from previous session (saved by previous call to fetchAuctionToComplete)
 */
function fetchAuctionToComplete(auctionId, dispatch, getState, afterPrepayment = false) {
    const state = getState();

    // cfr http://dev-aftersalesservice.cotw.eu/swagger/index.html

    const getAuctionToCompleteUrl = url('myaccount/ordercompletion/AuctionToComplete?auctionid=' + auctionId, state.common.currentLanguage);
    const priceInfoUrl = url(`carv6/bidpriceinfo?auctionId=${auctionId}&bidAmount=0`, state.common.currentLanguage);
    return Promise.all([
        afterPrepayment ? Promise.resolve(JSON.parse(localStorage.getItem(auctionToCompleteStorageKey))) : fetchJson(getAuctionToCompleteUrl),
        fetchJson(priceInfoUrl),
    ]).then(([auction, priceInfo]) => {
        if (!afterPrepayment) {
            // save for later call.
            localStorage.setItem(auctionToCompleteStorageKey, JSON.stringify(auction));
        } else {
            localStorage.removeItem(auctionToCompleteStorageKey);
        }
        dispatch({
            type: INIT,
            auction: auctionToState(auction, priceInfo, state.common.currentLanguage),
            invoicingAddressCanBeEdited: auction.newInvoiceAddressAllowed,
            isEu: auction.isEuBuyer,
            isUkOrderCompletion: auction.isUkOrderCompletion,
            isNonTransactionalAuction: auction.isNonTransactional,
        });
    });
}

const toStateAddr = addr => ({
    id: addr.addressId,
    street1: addr.street1,
    street2: addr.street2,
    zipCode: addr.zipCode,
    municipality: addr.municipality,
    country: addr.countryId,
    state: addr.state_Province,

    addressTypeId: addr.addressTypeId,
    isDefaultDeliveryAddress: addr.isDefaultDeliveryAddress,
    //companyId: addr.companyId,
    //salesForceId: addr.salesForceId,
});

function fetchAddresses(dispatch, getState) {
    const state = getState();
    const addressesUrl = url('myaccount/myaddressesv6/addresses', state.common.currentLanguage);
    return fetchJson(addressesUrl).then(resp => {
        const addressesList = [
            ...resp.filter(a => a.addressTypeId === ADDRESS_TYPE.MAIN).slice(0, 1), // first item = main addres
            ...resp.filter(a => a.addressTypeId === ADDRESS_TYPE.DELIVERY && a.isDefaultDeliveryAddress).slice(0, 1), // put default delivery address first (if any)
            ...resp.filter(a => a.addressTypeId === ADDRESS_TYPE.DELIVERY && !a.isDefaultDeliveryAddress).slice(0, 2), // 2,3th items = delivery address (most recent ones)
        ].slice(0, 3); // limit to 3 anyway...
        const addresses = addressesList.map(toStateAddr);
        const invoicingAddress = resp.filter(a => a.addressTypeId === ADDRESS_TYPE.INVOICE).map(toStateAddr)[0];
        dispatch({ type: FETCHED_ADDRESSES, addresses, invoicingAddress });

        // Select main Address by default unless there is a default delivery address.
        if (addresses.length > 0) {
            const defaultDelivery = addresses.filter(a => a.addressTypeId === ADDRESS_TYPE.DELIVERY && a.isDefaultDeliveryAddress)[0];
            const selectedId = defaultDelivery ? defaultDelivery.id : addresses[0].id;
            return selectedId;
        }
    });
}

function addDeliveryAddress(address, dispatch, getState) {
    const state = getState();
    address.addressTypeId = ADDRESS_TYPE.DELIVERY;
    address.companyId = state.common.currentUser.companyId;
    addAddress(addressToDto(address), state, dispatch).then(() => fetchAddresses(dispatch, getState));
}

function saveInvoicingAddress(address, dispatch, getState) {
    const state = getState();
    address.addressTypeId = ADDRESS_TYPE.INVOICE;
    address.companyId = state.common.currentUser.companyId;
    addAddress(addressToDto(address), state, dispatch).then(newAddress =>
        dispatch({ type: SAVED_INVOICING_ADDRESS, address: { ...address, id: newAddress.addressId } }),
    );
}

function setDeliveryAddressAsDefault(address, dispatch, getState) {
    const state = getState();

    // remove current default.
    let done = Promise.resolve();
    const addresses = state.orderCompletion.options.addresses || [];
    const currentDefault = addresses.filter(a => a.addressTypeId === ADDRESS_TYPE.DELIVERY && a.isDefaultDeliveryAddress)[0];
    if (currentDefault) {
        done = updateAddress(addressToDto({ ...currentDefault, isDefaultDeliveryAddress: false }), state, dispatch);
    }
    done.then(() => updateAddress(addressToDto({ ...address, isDefaultDeliveryAddress: true }), state, dispatch)).then(() =>
        fetchAddresses(dispatch, getState),
    );
}

const addressToDto = address => ({
    addressId: address.id,
    //CompanyId: address.companyId,
    //SalesForceId: address.salesForceId,
    //AddressTypeId: address.addressTypeId,
    countryId: address.country,
    //Enabled: true,
    municipality: address.municipality,
    street1: address.street1,
    street2: address.street2,
    zipCode: address.zipCode,
    state_Province: address.state,
    isDefaultDeliveryAddress: address.isDefaultDeliveryAddress,
});

function addAddress(addressDto, state, dispatch) {
    const addressesUrl = url('myaccount/myaddressesv6/deliveryaddresses', state.common.currentLanguage);
    return fetchJson(addressesUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(addressDto),
    }).catch(e => dispatch(notify({ message: 'Error while creating address:  ' + e.toString(), status: 'error' })));
}

function updateAddress(addressDto, state, dispatch) {
    const addressesUrl = url('myaccount/myaddressesv6/deliveryaddresses/' + addressDto.addressId, state.common.currentLanguage);
    return fetchJson(addressesUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(addressDto),
    }).catch(e => dispatch(notify({ message: 'Error while updating address:  ' + e.toString(), status: 'error' })));
}

function removeDeliveryAddress(id, dispatch, getState) {
    const state = getState();
    const addressesUrl = url('myaccount/myaddressesv6/deliveryaddresses/' + id, state.common.currentLanguage);
    fetchJson(addressesUrl, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
    }).then(resp => {
        dispatch({ type: REMOVE_DELIVERY_ADDRESS, id });
        // selected address is changed so fetch delivery options for the selected address.
        fetchDeliveryOptions(dispatch, getState);
    });
}

function fetchDeliveryOptions(dispatch, getState) {
    const state = getState();
    const deliveryOptionsUrl = url('myaccount/ordercompletion/GetDeliveryOptions?', state.common.currentLanguage);
    const addressId = state.orderCompletion.options.selectedAddress;
    const auctionId = state.orderCompletion.auctionToOrder.auctionId;
    return fetchJson(deliveryOptionsUrl + queryString({ auctionId, addressId })).then(data => {
        dispatch({ type: FETCHED_DELIVERY_OPTIONS, data });
        // auto select transport mode if only 1 available.
        const transportModes = getState().orderCompletion.options.transportModes;
        if (transportModes.length === 1) {
            dispatch({ type: SELECT_TRANSPORT_MODE, selectedId: transportModes[0].id });
        }
    });
}

function fetchAdditionalServices(auctionId, dispatch, state) {
    const additionalServicesUrl = url('myaccount/ordercompletion/GetServices?', state.common.currentLanguage);
    fetchJson(additionalServicesUrl + queryString({ auctionId })).then(data => dispatch({ type: FETCHED_ADDITIONAL_SERVICES, data }));
    // response structure : [{CanBeChanged: true, IsSelected: false, Price: 25, ServiceName: "CopyCarDoc"}, ...]
}

function fetchDocument(auctionId, documentType, dispatch, state) {
    const documentsUrl = url('myaccount/ordercompletion/GetDocument?', state.common.currentLanguage);
    return fetchJson(documentsUrl + queryString({ auctionId, documentType })).then(data => dispatch({ type: FETCHED_DOCUMENT, data }));
}

// fetch info to display what's still remaining to be paid.
function fetchRemainingPaymentDetails(auctionId, dispatch, state) {
    const paymentsDetailsUrl = url('myaccount/ordercompletion/GetPaymentInfo?' + queryString({ auctionId }), state.common.currentLanguage);
    return Promise.all([
        fetchJson(paymentsDetailsUrl).catch(() => ({ CarLocationDetail: {}, BankAccountDetails: {} })), // display blank if issue cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/670438
    ]).then(([paymentDetails, nextStepsDetails]) => {
        dispatch({ type: SET_REMAINING_PAYMENT_DETAILS, paymentDetails });
    });
}

const saveConfirmRequest = order => ({
    ...order.options,
    invoicingAddressId: order.invoicingAddress ? order.invoicingAddress.id : null,
    ecadisCountryId: order.auctionToOrder.ecadisCountryId,
    selectedBuyerPromos: order.options.buyerPromos
        .filter(a => order.options.selectedBuyerPromos.indexOf(a.id) > -1)
        .map(a => ({ PromoId: a.PromotionId })),
    isVatAppliedToPrepayment: order.summary.vatAppliedToTransportAndOptimo,
});

function saveSelectedOptions(dispath, getState) {
    const state = getState();
    const order = state.orderCompletion;
    const auctionId = order.auctionToOrder.auctionId;
    const saveUrl = url('myaccount/ordercompletion/SavePrePayment?', state.common.currentLanguage);
    return fetchJson(saveUrl + queryString({ auctionId }), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(saveConfirmRequest(order)),
    });
}

function fetchSavedOptions(auctionId, dispatch, state) {
    const savedOptionsUrl = url('myaccount/ordercompletion/GetPrePayment?' + queryString({ auctionId }), state.common.currentLanguage);
    fetchJson(savedOptionsUrl).then(resp => {
        const options = JSON.parse(resp.orderCompletionJsonObject);
        options.selectedBuyerPromos = options.selectedBuyerPromos.map(a => a.PromoId); // see here above saveConfirmRequest.
        dispatch({ type: SET_SELECTED_OPTIONS, options });
    });
}

function notifyMobileAppCompletionDone() {
    // Alert mobile app that completion is done successfully if we are in webview (inside the mobile app)
    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/640600
    // cfr https://medium.com/@svbala99/communication-between-react-native-web-view-and-react-app-c0fb0af7e5a6
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('orderCompletion/done');
    }
}
