import { RefObject, useEffect } from 'react';
import { useAppSettings } from './useAppSettings';
import { formatDate } from '../utils/date';

type Handler = (x: any) => void;

export const useMyAccountV2Bundle = (
    elementRef: RefObject<HTMLElement>,
    containerRef: RefObject<HTMLElement>,
    onMessage: Handler,
    onUnAuthorizedException: Handler,
    onDocumentsUploaded?: Handler,
) => {
    const appSettings = useAppSettings();

    const script = name => {
        const scriptBaseUrl = location.host.toLowerCase() === 'testcotwcarsite' ? 'https://carsite.dev.openlane.eu' : '';
        const script = document.createElement('script');
        const timestamp = formatDate(new Date(), 'yyyyMMddHH');
        script.src = `${scriptBaseUrl}/${name}.js?${timestamp}`;
        //script.src = `http://localhost:9001/bundle.js?${timestamp}`;
        script.async = true;
        return script;
    };

    useEffect(() => {
        if (appSettings.isLoading) return;

        if (!containerRef.current?.querySelector('script[src*=my-account]')) {
            containerRef.current?.appendChild(script('my-account-v2/bundle'));
        }
        if (elementRef.current) {
            // !! onMessage attribute on element itself doesn't work.
            // https://stackoverflow.com/questions/35448371/listening-for-web-component-events-in-react
            elementRef.current.addEventListener('message', onMessage);
            elementRef.current.addEventListener('unauthorized', onUnAuthorizedException);
            if (onDocumentsUploaded) elementRef.current.addEventListener('documentsUploaded', onDocumentsUploaded);
            return () => {
                elementRef.current?.removeEventListener('message', onMessage);
                elementRef.current?.addEventListener('unauthorized', onUnAuthorizedException);
                // on exit of myaccount, change of parameters in myaccount ==> refresh users details (example: showModal flag)
                //onRefreshUserDetails();
            };
        }
        return () => {};
    }, [appSettings.isLoading]);
};
