import i18n from '../../i18n';
import { uuidv4, distinct, currencyFormat } from '../../utils';
import settings from '../../appsettings';

import {
    //searchBySellersMetadata,
    prices,
    mileages,
    years,
    engineSizes,
    horsePowers,
    kWs,
    isLeasePlanClosed,
    EXTENDED_PHASE_STATUS,
} from '../cotw.js';
import { rangeSearchQueryNameMapping, checkboxSearchQueryNameMapping, facetsMapping, metadataFields, auctionToState } from './mappings';
import {
    INIT_METADATA,
    START_SEARCH,
    NEW_SEARCH_RESULT,
    NEXT_SEARCH_RESULT,
    NEW_SEARCH_COUNT,
    CHANGE_PAGE_SIZE,
    CHANGE_SORTING,
    CLEAR_SEARCH_CRITERIA,
    CHANGE_SEARCH_CRITERIA,
    SIGNALR_BID,
    AUCTION_EXTRA_DATA,
    AUCTION_EXTENDED,
    AUCTION_CLOSE_EXTENDED,
    XTIME_MESSAGE,
    SHOW_HIDE_FACET,
    ADD_FACET_COUNTS,
    CLOSE_AUCTION,
    SAVED_SEARCHES_GET,
    SAVED_SEARCHES_CREATE,
    SAVED_SEARCHES_UPDATE,
    SAVED_SEARCHES_DELETE,
    SAVED_SEARCHES_SELECT,
    CHANGE_POWER_UNIT,
    USER_PLACED_A_BID,
    SET_AUCTIONBATCH_TITLE,
    SET_SEO_TEXT,
    START_FETCHING_COMMUNITY,
    COMMUNITY_FECHED,
    SET_LOCATION_METADATA,
} from './actions';
import { sortingPreferenceStorageKey } from './common';
import { Sticker } from '../../components/ui/Sticker';

//-----------------------------------------------------------------------------------------------------------------
// CONFIG
//-----------------------------------------------------------------------------------------------------------------

const auctionBatchTypeMetadata = [
    { id: 1, name: 'auctionType.dynamic' },
    { id: 2, name: 'auctionType.buyNow' },
    { id: 3, name: 'auctionType.buyNowPickUp' },
    { id: 4, name: 'auctionType.blind' },
    { id: 5, name: 'auctionType.target' },
    { id: 6, name: 'auctionType.247StockSale', component: Sticker },
];

//     1 No damage -> IsBroken == 0 && HasTechnicalDamage == 0
//     2 Body damage -> IsBroken == 1 && HasTechnicalDamage == 0
//     3 Technical damage -> IsBroken == 0 && HasTechnicalDamage == 1
//     4 Body & Technical damage -> IsBroken == 1 && HasTechnicalDamage == 1
const damageMetadata = [
    { id: 1, name: 'damage.noDamage' },
    { id: 2, name: 'damage.bodyDamage' },
    { id: 3, name: 'damage.technicalDamage' },
    { id: 4, name: 'damage.bodyAndTechnicalDamage' },
];

const premiumOfferMetadata = [
    // { id: 1, name: 'premiumOffer.adesaBest' },
    // { id: 2, name: 'premiumOffer.100pcVerified' },
    { id: '247StockSale', name: 'premiumOffer.247StockSale', component: Sticker },
    { id: 'fastRelease', name: 'premiumOffer.fastRelease' },
    { id: 'highChance', name: 'premiumOffer.highChance' },
    { id: 'describedCorrectly', name: 'premiumOffer.describedCorrectly' },
    { id: 'exclusiveAtAdesa', name: 'premiumOffer.exclusiveAtAdesa' },
];

// IsMargin = true => “VAT non-deductible” + logo
// IsMargin = false => “VAT deductible”
const vatRegimesMetadata = [
    { id: 1, name: 'vatRegime.margin' }, //UK+EU margin cars
];

const xTimesMetadata = [
    //{id: false, name: 'No x-time'},
    { id: true, name: 'xtime.only' },
];

const assignment100pcMetadata = [{ id: true, name: 'highAssignment.only' }];

const isCarOnStockMetadata = [{ id: true, name: 'carOnStock.only-TBD' }];

const isLocalDealerCarMetadata = [{ id: true, name: 'facet.isLocalDealerCar' }];

const auctionsChannelMetadata = [
    //{ id: 'all', name: 'carSearch.auctionsChannel.allAuctionsChannel' },
    { id: 'adesa', name: 'carSearch.auctionsChannel.adesa' }, // counts of auctions for transactional (through ADESA)
    { id: 'myCommunities', name: 'carSearch.auctionsChannel.myCommunities' }, // counts of auctions for my joined communities
    { id: 'nonTransactional', name: 'carSearch.auctionsChannel.nonTransactional' }, // counts of auctions for nonTransactional (based on my country)
];

const vehicleGradesMetadata = [
    { id: 1, name: 'vehicleGrades.grade1' },
    { id: 2, name: 'vehicleGrades.grade2' },
    { id: 3, name: 'vehicleGrades.grade3' },
    { id: 4, name: 'vehicleGrades.grade4' },
    { id: 5, name: 'vehicleGrades.grade5' },
    { id: 99, name: 'vehicleGrades.unclassified' },
];

const mechanicalIndicatorsMetadata = [
    { id: 1, name: 'mechanicalIndicators.noIndicator' },
    { id: 2, name: 'mechanicalIndicators.majorMechanicalFault' },
    { id: 3, name: 'mechanicalIndicators.noMajor' },
    { id: 4, name: 'mechanicalIndicators.advisory' },
];

const placesMetadata = [
    ...Array(10)
        .fill()
        .map((_, i) => ({ id: i + 1, name: `${i + 1}` })),
    { id: 11, name: '> 10' },
];

const emptySearchCriteria = {
    makesModels: [], // [{make: 1, models: [33,44,55]}],
    prices: { from: null, to: null },
    mileages: { from: null, to: null },
    registrationYears: { from: null, to: null },
    engineSizes: { from: null, to: null },
    horsePowers: { from: null, to: null },
    kWs: { from: null, to: null },
    co2: { from: null, to: null },
    bodyTypes: [], // list of ids
    fuelTypes: [], // list of ids
    originCountries: [],
    transmissionTypes: [], // list of ids
    effluentStandards: [], // list of ids
    bodyColors: [], // list of ids
    vatRegimes: [], // list of ids 1= Margin(EU+UK), 2=NonMArginEu(OnlyEUCars), 3=Qualifying(onlyUKCars)
    damageTypes: [], // list of ids
    equipments: [], // list of ids
    auctionTypes: [],
    sellers: [],
    sellerGroups: [],
    xTimes: [],
    assignment100pc: [],
    isCarOnStock: [],
    currentAuctionBatchId: null,
    auctionIds: [],
    currentCommunityId: null,
    premiumOffer: [],
    isLocalDealerCar: [],
    auctionsChannel: [],
    vehicleGrades: [],
    mechanicalIndicators: [],
    places: [],
    vehicleLocation: {
        default: {
            zipCode: '',
            latitude: 0,
            longitude: 0,
        },
        country: '',
        zipCode: '',
        latitude: 0,
        longitude: 0,
        radius: 0,
        mileageType: 'km',
    },
};

const defaultSorting = {
    byUserPreference: true,
    field: null,
    direction: 'ascending',
};
const sorting = localStorage.getItem(sortingPreferenceStorageKey) ? JSON.parse(localStorage.getItem(sortingPreferenceStorageKey)) : defaultSorting;

const initialState = {
    currentLanguage: 'en',
    serverDate: new Date(),

    selectedSavedSearch: null, // currently selected saved search.
    searching: true,
    savedSearches: [],

    metadata: {
        ...metadataFields,
        makesModels: [],

        prices: [],
        mileages: [],
        registrationYears: [],
        engineSizes: [],
        horsePowers: [],
        kWs: [],
        bodyTypes: [],
        fuelTypes: [],
        transmissionTypes: [],
        effluentStandards: [],
        bodyColors: [],

        damageTypes: [],
        equipments: [],
        auctionTypes: [],
        originCountries: [],
        sellers: [],
        sellerGroups: [],
        xTimes: [],
        assignment100pc: [],
        isCarOnStock: [],
        vatRegimes: [],
        premiumOffer: [],
        isLocalDealerCar: [],
        auctionsChannel: [],
        vehicleGrades: [],
        mechanicalIndicators: [],
        places: [],
        vehicleLocation: [], // contains list of matching zipCode/places to display in combo.
    },

    searchCriteria: {
        ...emptySearchCriteria,
    },

    visibleFacets: ['makesModels', 'fuelTypes', 'transmissionTypes', 'premiumOffer'],

    paging: {
        pageSize: 20,
        pageIndex: 0, // 0 = first page.
    },
    sorting,

    searchGuid: uuidv4(), // used for stats in backend. identify a search. each time a criteria is changed, this guid is updated.
    auctionsCount: 0,
    auctionsList: [],

    currentPowerUnit: 'horsePowers',

    auctionBatchTitlesByLanguage: {},

    currentCommunity: null,

    seoText: null,

    dummy: 'INIT',
};

// converts list [{Key: "aa", Value: 1}, ...] to {aa: 1 ,...}
function toMap(list) {
    return list.reduce((res, v) => {
        res[v.Key] = v.Value;
        if (typeof v.Key === 'string') res[v.Key.toLowerCase()] = v.Value; // save key to lowercase too (as facets are all sent in lowercase)
        return res;
    }, {});
}
const toLowerCase = a => (typeof a === 'string' ? a.toLowerCase() : a);

// Apply facets to the metadata structure by adding the corresponding counts.
function metadataWithUpToDateCounts(metadata, facets) {
    const result = { ...metadata };
    Object.keys(facetsMapping).forEach(k => {
        if (facets[facetsMapping[k]]) {
            const counts = toMap(facets[facetsMapping[k]]);
            if (k === 'vatRegimes' || k === 'xTimes' || k === 'assignment100pc' || k === 'isCarOnStock' || k === 'isLocalDealerCar') {
                counts[true] = counts['1'];
                counts[false] = counts['0'];
            }
            result[k] = result[k].map(a => ({ ...a, count: counts[toLowerCase(k === 'sellers' ? a.name : a.id)] || 0 }));
        }
    });

    // retrieve 'counts' for models.
    if (facets.CleanModel) {
        const counts = toMap(facets.CleanModel);
        result.makesModels = result.makesModels.map(a => ({
            ...a,
            models: a.models.map(b => ({ ...b, count: counts[toLowerCase(/*a.id + "|" +*/ b.id)] })),
        }));
    }

    return result;
}

export function carSearchReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_SEARCH_CRITERIA: {
            let newSelection = action.newSelection;

            // skip invalid criteria.
            if (action.criteriaName === 'makesModels') {
                const makes = state.metadata.makesModels.map(a => a.id);
                newSelection = newSelection.filter(a => a.make === null || makes.indexOf(a.make) > -1);
                // make=null when adding a new make/models criteria.
            }
            const checkboxKeys = Object.keys(checkboxSearchQueryNameMapping);
            if (checkboxKeys.indexOf(action.criteriaName) > -1) {
                const ids = state.metadata[action.criteriaName].map(a => a.id);
                if (!Array.isArray(ids)) {
                    newSelection = newSelection.filter(a => ids.indexOf(a) > -1);
                }
            }
            const rangeKeys = Object.keys(rangeSearchQueryNameMapping);
            if (rangeKeys.indexOf(action.criteriaName) > -1 && action.criteriaName !== 'co2') {
                // skip for textRangeCriteria
                const values = state.metadata[action.criteriaName].map(a => a.id);
                newSelection = {
                    from: newSelection.from === 0 || values.indexOf(newSelection.from) > -1 ? newSelection.from : null,
                    to: newSelection.to === 0 || values.indexOf(newSelection.to) > -1 ? newSelection.to : null,
                };
            }

            return {
                ...state,
                dummy: action.type,
                selectedSavedSearch: null,
                searchGuid: action.searchGuid,
                seoText: null,
                searchCriteria: {
                    ...state.searchCriteria,
                    [action.criteriaName]: newSelection,
                },
            };
        }

        case INIT_METADATA: {
            let filteredAuctionBatchTypeMetadata = auctionBatchTypeMetadata;

            //#725602 handle vat regime for specific Catalogue
            const hasEuCatalogueAccess = action.allowedCatalogues && action.allowedCatalogues.includes('ADESA EU');
            const hasUkCatalogueAccess = action.allowedCatalogues && action.allowedCatalogues.includes('ADESA UK');
            let vatRegimeLabel = vatRegimesMetadata;
            if (action.isUserLoggedIn) {
                if (hasUkCatalogueAccess && hasEuCatalogueAccess) {
                    vatRegimeLabel = [...vatRegimeLabel, { id: 2, name: 'vatRegime.nonMargin' }, { id: 3, name: 'vatRegime.qualifying' }];
                } else {
                    if (hasUkCatalogueAccess) {
                        vatRegimeLabel = [...vatRegimeLabel, { id: 3, name: 'vatRegime.qualifying' }]; //UK Non margin cars
                    }
                    if (hasEuCatalogueAccess) {
                        vatRegimeLabel = [...vatRegimeLabel, { id: 2, name: 'vatRegime.nonMargin' }]; //EU Non margin cars
                    }
                }
            } else {
                vatRegimeLabel = [...vatRegimeLabel, { id: 2, name: 'vatRegime.nonMargin' }, { id: 3, name: 'vatRegime.qualifying' }];
            }
            return {
                ...state,
                currentLanguage: action.currentLanguage,
                manageQueriesUrl: `/${action.currentLanguage}/mycotw/searches`,
                //savedSearches: action.savedSearches, // because of conflict when going to saved search page directly!! #2941

                dummy: action.type,
                metadata: {
                    ...state.metadata,
                    makesModels: action.metadata.MakesModels.map((item, idx) => ({
                        id: item.Make, // the name itself is used by the search service as id.
                        //id: idx+1, // the name itself is used by the search service as id.
                        name: item.Make,
                        models: item.Models.map(name => ({ id: name, name })),
                        //models: namesToIdNameList(item.Models),
                    })),

                    prices: prices,
                    mileages: mileages(i18n.t.bind(i18n)),
                    registrationYears: years,

                    engineSizes: engineSizes(i18n.t.bind(i18n)),
                    horsePowers: horsePowers(i18n.t.bind(i18n)),
                    kWs: kWs(i18n.t.bind(i18n)),

                    bodyTypes: action.metadata.BodyTypes.map(a => ({ id: a, name: 'bodyType.' + a.toLowerCase() })),
                    fuelTypes: action.metadata.FuelTypes.map(a => ({ id: a, name: 'fuelType.' + a.toLowerCase() })),
                    transmissionTypes: action.metadata.GearboxTypes.map(a => ({ id: a, name: 'transmission.' + a.toLowerCase() })),
                    originCountries: action.metadata.OriginCountries.map(a => ({ id: a, name: 'country.' + a })),

                    effluentStandards: action.metadata.EffluentStandards.map(a => ({ id: a, name: 'emissionStandard.' + a })),
                    bodyColors: action.metadata.BodyColors.map(a => ({ id: a, name: 'common.color.' + a.toLowerCase() })),
                    equipments: action.metadata.Equipments.map(a => ({ id: a, name: 'equipment.' + a.replace(/\s/g, '') })),
                    auctionTypes: filteredAuctionBatchTypeMetadata,
                    damageTypes: damageMetadata,
                    vatRegimes: vatRegimeLabel,
                    // 'sellers' is deprecated (by sellerGroups), kept to avoid breaking using seller ids for query.
                    sellers: Object.keys(action.sellerGroups).map(sg => ({ name: sg, id: action.sellerGroups[sg] })), // {name: 'name of seller group', id: [list of seller id]}
                    sellerGroups: Object.keys(action.sellerGroups).map(sg => ({ name: sg, id: sg })), // {name: 'name of seller group', id: 'name of seller group'}
                    xTimes: xTimesMetadata,
                    assignment100pc: assignment100pcMetadata,
                    isCarOnStock: isCarOnStockMetadata,
                    premiumOffer: premiumOfferMetadata,
                    isLocalDealerCar: isLocalDealerCarMetadata,
                    auctionsChannel: auctionsChannelMetadata.filter(
                        a => settings.featureFlags['isNonTransactionalEnabled'] || a.id !== 'nonTransactional',
                    ),
                    vehicleGrades: vehicleGradesMetadata,
                    mechanicalIndicators: mechanicalIndicatorsMetadata,
                    places: placesMetadata,
                    vehicleLocation: [],
                },
            };
        }

        case START_SEARCH:
            return {
                ...state,
                dummy: action.type,
                searching: true,
            };

        case CLEAR_SEARCH_CRITERIA:
            return {
                ...state,
                dummy: action.type,
                // reset vehicleLocation metadata.
                metadata: {
                    ...state.metadata,
                    vehicleLocation: [],
                },
                searchCriteria: {
                    ...emptySearchCriteria,
                    // clear radius and reset vehicle location to default.
                    vehicleLocation: {
                        ...state.searchCriteria.vehicleLocation,
                        id: state.searchCriteria.vehicleLocation.id,
                        zipCode: state.searchCriteria.vehicleLocation.default.zipCode,
                        place: state.searchCriteria.vehicleLocation.default.place,
                        postalCode: state.searchCriteria.vehicleLocation.default.postalCode,
                        latitude: state.searchCriteria.vehicleLocation.default.latitude,
                        longitude: state.searchCriteria.vehicleLocation.default.longitude,
                        radius: 0,
                    },
                },
                auctionsList: [],
                auctionsCount: 0,
                selectedSavedSearch: null,
                seoText: null,
            };

        case CHANGE_PAGE_SIZE:
            return {
                ...state,
                paging: {
                    ...state.paging,
                    pageIndex: 0,
                    pageSize: action.pageSize,
                },
                dummy: action.type,
            };

        case CHANGE_SORTING: {
            const [field, direction] = action.sorting.split('|');
            const byUserPreference = field === 'UserPreference';
            return {
                ...state,
                sorting: {
                    ...state.sorting,
                    field,
                    direction,
                    byUserPreference,
                },
                dummy: action.type,
            };
        }
        case NEW_SEARCH_RESULT: {
            const auctionsList = action.result.Auctions.map(a => auctionToState(a, action.currentLanguage, action.contactId));
            const metadata = metadataWithUpToDateCounts(state.metadata, action.result.Facets);
            if (action.emptyCriteriaSearch) {
                //TBD Vehicle Location Search;
                // update counts for channels (adesa (all - community - direct), community, direct)
                let communityCount = 0;
                if (action.isUserLoggedIn && action.isJoinedCommunity) {
                    if (action.result.Facets.CommunityId) {
                        const counts = toMap(action.result.Facets.CommunityId); // counts of auction per communityId
                        communityCount = action.myCommunityIds.reduce((tot, id) => tot + (counts[id] || 0), 0);
                        const found = metadata.auctionsChannel.find(a => a.id === 'myCommunities');
                        if (found) found.count = communityCount;
                    }
                } else {
                    // don't show the "communities" filter for not logged in users and non community users.
                    metadata.auctionsChannel = metadata.auctionsChannel.filter(a => a.id !== 'myCommunities');
                }
                let nonTransCount = 0;
                if (action.result.Facets.NonTransactionalCountries) {
                    const counts = toMap(action.result.Facets.NonTransactionalCountries); // counts of non transactional auctions per buyer country.
                    nonTransCount = action.userHasFullAccess && !!counts[action.currentCountry] ? counts[action.currentCountry] : 0; // only keep count for current buyer country.
                    const nonTrans = metadata.auctionsChannel.find(a => a.id === 'nonTransactional');
                    if (nonTrans) nonTrans.count = nonTransCount;
                }
                const adesa = metadata.auctionsChannel.find(a => a.id === 'adesa');
                if (adesa) adesa.count = action.result.Count - nonTransCount - communityCount;
            }
            return {
                ...state,
                metadata,
                paging: {
                    ...state.paging,
                    pageIndex: 0,
                },
                searching: false,
                auctionsCount: action.result.Count,
                auctionsList,
                dummy: action.type,
            };
        }

        case ADD_FACET_COUNTS: {
            return {
                ...state,
                metadata: metadataWithUpToDateCounts(state.metadata, action.facets),
            };
        }

        case NEXT_SEARCH_RESULT: {
            const auctionsList = action.result.Auctions.map(a => auctionToState(a, action.currentLanguage, action.contactId));

            return {
                ...state,
                paging: {
                    ...state.paging,
                    pageIndex: action.pageIndex,
                },
                searching: false,
                auctionsList,
                dummy: action.type,
            };
        }

        case AUCTION_EXTRA_DATA:
            return {
                ...state,
                auctionsList: state.auctionsList.map(a => ({ ...a, ...action.data[a.auctionId] })),
            };

        case NEW_SEARCH_COUNT:
            return {
                ...state,
                auctionsCount: action.result.Count,
                dummy: action.type,
            };

        case SIGNALR_BID: {
            return {
                ...state,
                auctionsList: state.auctionsList.map(a => (a.auctionId === action.auctionId ? { ...a, ...action.auctionDataUpdate } : { ...a })),
                dummy: action.type,
            };
        }

        case SAVED_SEARCHES_GET: {
            return {
                ...state,
                savedSearches: [...action.list],
                //savedSearches: [...action.list, ...action.list, ...action.list, ...action.list, ...action.list, ...action.list, ...action.list, ...action.list, ...action.list],
                //savedSearches: savedSearchesSamples,
                selectedSavedSearch: null,
                dummy: action.type,
            };
        }

        case SAVED_SEARCHES_CREATE: {
            return {
                ...state,
                selectedSavedSearch: action.savedSearch,
                //savedSearches: [...state.savedSearches, action.savedSearch],
                dummy: action.type,
            };
        }

        case SAVED_SEARCHES_UPDATE: {
            return {
                ...state,
                savedSearches: state.savedSearches.map(ss => (ss.id == action.savedSearch.id ? { ...ss, ...action.savedSearch } : { ...ss })),
                selectedSavedSearch: null,
                dummy: action.type,
            };
        }

        case SAVED_SEARCHES_DELETE: {
            return {
                ...state,
                savedSearches: state.savedSearches.filter(a => action.ids.indexOf(a.id) === -1),
                selectedSavedSearch: null,
                dummy: action.type,
            };
        }

        case SAVED_SEARCHES_SELECT: {
            const savedSearch = state.savedSearches.filter(a => a.id === action.id)[0];
            return {
                ...state,
                selectedSavedSearch: savedSearch,
                searchGuid: action.searchGuid,
                searchCriteria: savedSearch.query.searchCriteria,
                sorting: savedSearch.query.sorting,
                paging: savedSearch.query.paging,
                dummy: action.type,
            };
        }

        case CHANGE_POWER_UNIT:
            return {
                ...state,
                currentPowerUnit: action.powerUnit,
            };

        case USER_PLACED_A_BID: {
            return {
                ...state,
                auctionsList: state.auctionsList.map(a => (a.auctionId === action.auctionId ? { ...a, hasUserPlacedABid: true } : { ...a })),
            };
        }

        case AUCTION_EXTENDED: {
            // called by signalr
            return {
                ...state,
                auctionsList: state.auctionsList.map(a =>
                    a.auctionId === action.auctionId ? { ...a, isInExtendedPhase: true, endDate: action.endDate } : { ...a },
                ),
            };
        }

        case AUCTION_CLOSE_EXTENDED: {
            // called by signalr
            return {
                ...state,
                auctionsList: state.auctionsList.map(a =>
                    a.auctionId === action.auctionId ? { ...a, isClosed: true, isExtendedPhaseFinished: true } : { ...a },
                ),
            };
        }
        case XTIME_MESSAGE: {
            // called by signalr
            return {
                ...state,
                auctionsList: state.auctionsList.map(a =>
                    a.auctionId === action.auctionId ? { ...a, endDate: action.endDate, currentPrice: action.currentPrice } : { ...a },
                ),
            };
        }

        case CLOSE_AUCTION: {
            const isClosed = a =>
                a.isAlphabetLikeXTime
                    ? a.extendedPhaseStatusId === EXTENDED_PHASE_STATUS.CLOSED
                    : a.isLeasePlanLikeXTime
                    ? isLeasePlanClosed(a.endDate)
                    : true;

            return {
                ...state,
                auctionsList: state.auctionsList.map(a => (a.auctionId === action.auctionId ? { ...a, isClosed: isClosed(a) } : { ...a })),
            };
        }

        case SET_AUCTIONBATCH_TITLE: {
            return {
                ...state,
                auctionBatchTitlesByLanguage:
                    action.auctionBatch && action.auctionBatch.AuctionBatchNameList ? action.auctionBatch.AuctionBatchNameList : {},
            };
        }
        case SHOW_HIDE_FACET: {
            const visibleFacets = action.open
                ? [...state.visibleFacets, action.name].filter(distinct)
                : state.visibleFacets.filter(a => a !== action.name);
            return {
                ...state,
                visibleFacets,
            };
        }

        case SET_SEO_TEXT: {
            return {
                ...state,
                seoText: action.seoText,
            };
        }

        case START_FETCHING_COMMUNITY: {
            return {
                ...state,
                currentCommunity: null,
            };
        }

        case COMMUNITY_FECHED: {
            return {
                ...state,
                currentCommunity: action.community,
            };
        }

        case SET_LOCATION_METADATA: {
            return {
                ...state,
                metadata: { ...state.metadata, vehicleLocation: action.metadata },
            };
        }

        default:
            return { ...state };
    }
}
