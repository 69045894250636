import React from 'react';

import { Footer, FooterBrandElements, FooterContent, FooterListCategory, FooterListItem, useDesktop } from '@ambiorix/adesa-web-kit';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faSquareXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cx } from 'class-variance-authority';

import styles from './MainFooter.module.scss';

import { cmsUrl } from '../../utils';
import { sellCarsLink } from '../../state/cotw';

import settings from '../../appsettings';

const cmsLink = (t: (key: string) => string, language: string) => (suffix: string, className?: string) =>
(
    <a className={className} href={cmsUrl(language, t(`footer.${suffix}Link`))}>
        {t(`footer.${suffix}`)}
    </a>
);
const sellCarsUrl = (t: (key: string) => string, language: string) => (suffix: string, className?: string) =>
(
    <FooterListItem className={cx(styles.listItem, className)} href={sellCarsLink(language)}>
        {t(`footer.${suffix}`)}
    </FooterListItem>
);
const listItemLink = (t: (key: string) => string, language: string) => (suffix: string, className?: string) =>
(
    <FooterListItem className={cx(styles.listItem, className)} href={cmsUrl(language, t(`footer.${suffix}Link`))}>
        {t(`footer.${suffix}`)}
    </FooterListItem>
);

const socials = [
    {
        className: 'uitest-facebook-link',
        href: 'https://www.facebook.com/OPENLANE.Europe',
        icon: <FontAwesomeIcon icon={faFacebookSquare} />,
        title: 'Facebook',
    },
    {
        className: 'uitest-linkedin-link',
        href: 'https://www.linkedin.com/company/openlane-europe',
        icon: <FontAwesomeIcon icon={faLinkedin} />,
        title: 'LinkedIn',
    },
    {
        className: 'uitest-youtube-link',
        href: 'https://www.youtube.com/@OPENLANEEurope',
        icon: <FontAwesomeIcon icon={faYoutube} />,
        title: 'YouTube',
    },
    {
        className: 'uitest-twitter-link',
        href: 'https://x.com/OPENLANE_Europe',
        icon: <FontAwesomeIcon icon={faSquareXTwitter} />,
        title: 'X',
    },
    {
        className: 'uitest-instagram-link',
        href: 'https://www.instagram.com/lifeatopenlane_europe',
        icon: <FontAwesomeIcon icon={faInstagramSquare} />,
        title: 'Instagram',
    },
];

type MainFooterProps = {
    currentLanguage: string;
    t: (key: string) => string;
};

const MainFooter = ({ currentLanguage, t }: MainFooterProps) => {
    const isDesktop = useDesktop();

    const a = cmsLink(t, currentLanguage);
    const a2 = (suffix: string, className: string, url: string, target = '') => (
        <a className={className} href={url} target={target}>
            {t(`footer.${suffix}`)}
        </a>
    );
    const wordPressEnabled = !!settings.featureFlags['IsNewFooterEnabled'];
    const listItem = listItemLink(t, currentLanguage);
    const listItem2 = (suffix: string, className: string, url: string, target = '') => (
        <FooterListItem className={cx(styles.listItem, className)} href={url} target={target}>
            {t(`footer.${suffix}`)}
        </FooterListItem>
    );
    const listItemSell = sellCarsUrl(t, currentLanguage);

    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/114016
    const privacyPolicyUrl = `/${currentLanguage}/homev6/privacy-policy`;

    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/114017
    const exerciseRightsUrl = 'https://privacyportal.onetrust.com/webform/220aba37-6609-45b9-bb38-d90c71628891/1b2e349f-280d-4432-9357-f8e1ae9973e6';

    // button managed by the OneTrust script for cookie consent, loaded in the _LayoutV6.cshtml template.
    // cfr  https://karauctionservices.visualstudio.com/International/_workitems/edit/114018
    const cookieSettingsButton = (suffix: string, className?: string) => (
        <button id="ot-sdk-btn" className={cx(`ot-sdk-show-settings ${className}`, styles.onetrustButton)}>
            {t(`footer.${suffix}`)}
        </button>
    );
    const cookieSettingsListItem = (suffix: string, className?: string) => (
        <FooterListItem id="ot-sdk-btn" className={cx(styles.listItem, 'ot-sdk-show-settings', className, styles.onetrustButton)}>
            {t(`footer.${suffix}`)}
        </FooterListItem>
    );

    const logo = (
        <img
            key="logo"
            className={styles.logo}
            src="/v6/images/openlane-logo-light.svg"
            alt={t('common.brand.name')}
            title={t('common.brand.name')}
        />
    );

    const copyright = (
        <span>
            &copy; {new Date().getFullYear()} {t('common.brand.name')}. {t('common.rightsReserved')}
        </span>
    );

    let links = (
        <>
            {a('termsAndConditionsNew', 'uitest-terms-and-conditions-link')}
            {a('impressumNew', 'uitest-impressum-link')}
            {a2('privacyPolicy', 'uitest-privacy-policy-link', privacyPolicyUrl)}
            {a2('exerciseRights', 'uitest-exercise-rights-link', exerciseRightsUrl, '_blank')}
            {cookieSettingsButton('cookieSettings', 'uitest-cookie-settings-link')}
            {a('scamPrecaution', 'uitest-scam-precaution-link')}
        </>
    );
    if (!wordPressEnabled) {
        links = (
            <>
                {a('termsAndConditions', 'uitest-terms-and-conditions-link')}
                {a('impressum', 'uitest-impressum-link')}
                {a2('privacyPolicy', 'uitest-privacy-policy-link', privacyPolicyUrl)}
                {a2('exerciseRights', 'uitest-exercise-rights-link', exerciseRightsUrl, '_blank')}
                {cookieSettingsButton('cookieSettings', 'uitest-cookie-settings-link')}
            </>
        );
    }

    return (
        <Footer className={styles.mainFooter}>
            {wordPressEnabled ? (
                <FooterContent className={cx(styles.content, isDesktop && styles.container)} pattern>
                    <FooterListCategory title={t('footer.companyInformation')}>
                        {listItem('careers')}
                        {listItem('about')}
                        {listItem('investors')}
                        {listItem('mediaCentre')}
                        {listItem('blogNew')}
                    </FooterListCategory>
                    <FooterListCategory title={t('footer.buyVehicles')}>
                        {listItem('startToBuy')}
                        {listItem('buyerProductFeatures')}
                        {listItem('servicesForBuyers')}
                        {listItem('vehicleOffer')}
                        {listItem('buyerStories')}
                    </FooterListCategory>
                    <FooterListCategory title={t('footer.sellVehicles')}>
                        {listItem('startToSell')}
                        {listItem('sellerProductFeatures')}
                        {listItem('servicesForSellers')}
                        {listItem('forWho')}
                        {listItem('sellerStories')}
                    </FooterListCategory>
                    <FooterListCategory title={t('footer.help')}>
                        {listItem('contactNew')}
                        {listItem('faqNew')}
                        {listItem('instructionMovies')}
                        {listItem('closingDaysAndDelays')}
                        {listItem('newsletters')}
                        {listItem('releases')}
                    </FooterListCategory>
                    <FooterListCategory className={cx(isDesktop && styles.hidden)} title={t('footer.legal')}>
                        {listItem('termsAndConditionsNew', 'uitest-terms-and-conditions-link')}
                        {listItem('impressumNew', 'uitest-impressum-link')}
                        {listItem2('privacyPolicy', 'uitest-privacy-policy-link', privacyPolicyUrl)}
                        {listItem2('exerciseRights', 'uitest-exercise-rights-link', exerciseRightsUrl, '_blank')}
                        {cookieSettingsListItem('cookieSettings', 'uitest-cookie-settings-link')}
                        {listItem('scamPrecaution', 'uitest-scam-precaution-link')}
                    </FooterListCategory>
                </FooterContent>
            ) : (
                <FooterContent className={cx(styles.content, isDesktop && styles.container)} pattern>
                    <FooterListCategory title={t('footer.getToKnowUs')}>
                        {listItem('history')}
                        {listItem('blog')}
                        {listItem('pressKit')}
                        {listItem('jobs')}
                        {listItem('mission')}
                        {listItem('contact')}
                        {listItem('customerSuccessStories')}
                    </FooterListCategory>
                    <FooterListCategory title={t('footer.availableServices')}>
                        {listItemSell('sellingCars')}
                        {listItem('startInOurAuctions')}
                        {listItem('whyWorkWithUs')}
                        {listItem('orderTransport')}
                        {listItem('getExportExpertise')}
                        {listItem('startToBid')}
                        {listItem('downloadMobileApp')}
                    </FooterListCategory>
                    <FooterListCategory title={t('footer.productsOnSale')}>
                        {listItem('passengerCars')}
                        {listItem('vansAndLightTrucks')}
                        {listItem('damagedVehicles')}
                        {listItem('marginVehicles')}
                    </FooterListCategory>
                    <FooterListCategory title={t('footer.letUsHelpYou')}>
                        {listItem('faq')}
                        {listItem('howToRegister')}
                        {listItem('howToBid')}
                        {listItem('howToBuy')}
                        {listItem('customerService')}
                        {listItem('closingDays')}
                        {listItem('scamPrecaution')}
                    </FooterListCategory>
                    <FooterListCategory className={cx(isDesktop && styles.hidden)} title={t('footer.legal')}>
                        {listItem('termsAndConditions', 'uitest-terms-and-conditions-link')}
                        {listItem('impressum', 'uitest-impressum-link')}
                        {listItem2('privacyPolicy', 'uitest-privacy-policy-link', privacyPolicyUrl)}
                        {listItem2('exerciseRights', 'uitest-exercise-rights-link', exerciseRightsUrl, '_blank')}
                        {cookieSettingsListItem('cookieSettings', 'uitest-cookie-settings-link')}
                    </FooterListCategory>
                </FooterContent>
            )}
            <FooterBrandElements className={cx(isDesktop && styles.container)} logo={logo} copyright={copyright} socials={socials} links={links} />
        </Footer>
    );
};

export default MainFooter;
