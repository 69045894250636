import React from 'react';
import './CarEquipment.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const TopOptionIcon = () => (
    <span className="top-option-icon">
        <FontAwesomeIcon icon={faStar} size={'md'} />
    </span>
);

export default ({ allOptions, selectedOptions, accessories, optionComment, t }) => {
    const selectedOptionIds = selectedOptions.map(x => x.OptionId);

    const sourcedOptions = selectedOptions.filter(x => x.OptionId === -1 && x.ShowInCarDetail);
    // Group sourced options by category
    const sourcedOptionsGrouped = sourcedOptions.reduce((acc, x) => {
        if (!acc[x.CategoryId]) acc[x.CategoryId] = [];
        acc[x.CategoryId].push(x);
        return acc;
    }, {});

    const options = allOptions.filter(option => selectedOptionIds.includes(option.id));
    const parentIds = options.map(x => x.parentOptionId);
    let groups = options
        .map(({ optionGroupId }) => ({ optionGroup: t('dp_optionGroup.' + optionGroupId), optionGroupId }))
        .filter((item, pos, self) => self.findIndex(v => v.optionGroup === item.optionGroup) === pos)
        .sort((a, b) => a.optionGroup.localeCompare(b.optionGroup));
    // PUT OTHER LAST
    const otherGroup = groups.filter(x => x.optionGroupId === 6)[0];
    if (otherGroup) groups = [...groups.filter(x => x.optionGroupId !== 6), otherGroup];
    const highValue = options.filter(x => x.isHighValue).length > 0;
    groups.forEach(group => {
        const groupOptions = options
            .filter(x => x.optionGroupId === group.optionGroupId && !parentIds.includes(x.id))
            .map(({ id, parentOptionId, isHighValue }) => ({
                option: parentOptionId ? t('dp_option.' + parentOptionId) + ' - ' + t('dp_option.' + id) : t('dp_option.' + id),
                id,
                isHighValue,
            }))
            .sort((a, b) => a.option.localeCompare(b.option));
        group.options = groupOptions;
    });

    return (
        <div className="rc-CarEquipment">
            {highValue ? (
                <div className="high-value-option">
                    <span className="content">
                        <TopOptionIcon /> {' : '}
                        {t('vehicleDetails.equipment.topEquipment')}
                    </span>
                </div>
            ) : undefined}
            {groups.map(group => {
                return group.options && group.options.length > 0 ? (
                    <div key={group.optionGroupId} className="options">
                        <div className="label">{group.optionGroup}</div>
                        <div className="list">
                            {group.options.map(option => {
                                return (
                                    <div key={option.id}>
                                        <span className="name">{option.option}</span>
                                        {option.isHighValue ? <TopOptionIcon /> : undefined}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : undefined;
            })}

            {Object.keys(sourcedOptionsGrouped).map(
                categoryId =>
                    sourcedOptionsGrouped[categoryId] && (
                        <div key={categoryId} className="sourced-options">
                            <div className="label">{t(`caroption.category.${categoryId}`)}</div>
                            <div className="list">
                                {sourcedOptionsGrouped[categoryId].map(({ Name }) => (
                                    <div key={Name}>
                                        <span className="name">{Name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
            )}

            {accessories && accessories.length > 0 ? (
                <div className="accessories">
                    <div className="label">{t('vehicleDetails.equipment.suppliedWithCar')}</div>
                    <div className="list">
                        {accessories.map(accessory => {
                            return <div key={accessory.StockItemTypeId}>{t(accessory.LabelName)}</div>;
                        })}
                    </div>
                </div>
            ) : undefined}
            {optionComment && optionComment.length > 0 ? (
                <div className="comment">
                    <div className="label">{t('vehicleDetails.equipment.comments')}</div>
                    <div className="content">{optionComment}</div>
                </div>
            ) : undefined}
        </div>
    );
};
