export const INIT = 'ORDER_COMPLETION:INIT';
export const SET_COUNTRIES = 'ORDER_COMPLETION:SET_COUNTRIES';
export const SET_SELLER = 'ORDER_COMPLETION:SET_SELLER';
export const SELECT_TRANSPORT_MODE = 'ORDER_COMPLETION:SELECT_TRANSPORT_MODE';

export const FETCHED_ADDRESSES = 'ORDER_COMPLETION:FETCHED_ADDRESSES';
export const SELECT_DELIVERY_ADDRESS = 'ORDER_COMPLETION:SELECT_DELIVERY_ADDRESS';
export const EDIT_DELIVERY_ADDRESS = 'ORDER_COMPLETION:EDIT_DELIVERY_ADDRESS';
export const ADD_DELIVERY_ADDRESS = 'ORDER_COMPLETION:ADD_DELIVERY_ADDRESS';
export const REMOVE_DELIVERY_ADDRESS = 'ORDER_COMPLETION:REMOVE_DELIVERY_ADDRESS';

export const SELECT_FINANCIAL_SERVICE = 'ORDER_COMPLETION:SELECT_FINANCIAL_SERVICE';
export const SELECT_ADDITIONAL_SERVICES = 'ORDER_COMPLETION:SELECT_ADDITIONAL_SERVICES';
export const SELECT_BUYER_PROMO = 'ORDER_COMPLETION:SELECT_BUYER_PROMO';
export const SELECT_NON_EU_OPTION = 'ORDER_COMPLETION:SELECT_NON_EU_OPTION';
export const SELECT_PAYMENT_METHOD_UK = 'ORDER_COMPLETION:SELECT_PAYMENT_METHOD_UK';

export const SET_SELECTED_OPTIONS = 'ORDER_COMPLETION:SET_SELECTED_OPTIONS';

export const SELECT_DESTINATION_COUNTRY = 'ORDER_COMPLETION:SELECT_DESTINATION_COUNTRY';

export const FETCHED_DELIVERY_OPTIONS = 'ORDER_COMPLETION:FETCHED_DELIVERY_OPTIONS';
export const FETCHED_ADDITIONAL_SERVICES = 'ORDER_COMPLETION:FETCHED_ADDITIONAL_SERVICES';
export const FETCHED_DOCUMENT = 'ORDER_COMPLETION:FETCHED_DOCUMENT';

export const SHOW_OVERVIEW = 'ORDER_COMPLETION:SHOW_OVERVIEW';
export const SHOW_CONFIRMED_VIEW = 'ORDER_COMPLETION:SHOW_CONFIRMED_VIEW';
export const SAVED_INVOICING_ADDRESS = 'ORDER_COMPLETION:SAVED_INVOICING_ADDRESS';

export const GO_BACK = 'ORDER_COMPLETION:GO_BACK';
export const CHANGE_NON_EU_FIELDS = 'ORDER_COMPLETION:CHANGE_NON_EU_FIELDS';
export const CONFIRMATION_BUSY = 'ORDER_COMPLETION:CONFIRMATION_BUSY';
export const FETCHED_BUYER_PROMOS = 'ORDER_COMPLETION:FETCHED_BUYER_PROMOS';
export const SET_PAYMENT_STATUS = 'ORDER_COMPLETION:SET_PAYMENT_STATUS';
export const REDIRECTING_TO_OGONE = 'ORDER_COMPLETION:REDIRECTING_TO_OGONE';
export const SET_NOT_ALLOWED_DESTINATION_COUNTRY = 'ORDER_COMPLETION:SET_NOT_ALLOWED_DESTINATION_COUNTRY';
export const SET_NOT_ALLOWED_DELIVERY_ADDRESS_COUNTRY = 'ORDER_COMPLETION:SET_NOT_ALLOWED_DELIVERY_ADDRESS_COUNTRY';
export const SET_REMAINING_PAYMENT_DETAILS = 'ORDER_COMPLETION:SET_REMAINING_PAYMENT_DETAILS';
