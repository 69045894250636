import React from 'react';
import { InfoBanner } from '@ambiorix/adesa-web-kit';
import { CompanyOrContactBlocked } from '../../state/Login';
import useBanner, { BannerSessionStorageKeys } from '../../hooks/useBanner';
import { extrapolate } from '../../utils';

const goToCmsBlockedPage = (currentLanguage: string) => {
    window.open(`/${currentLanguage}/cms/frequently-asked-questions/login-account/why-is-my-account-blocked`, '_blank');
};

const goToCmsAwaitingPayment = (currentLanguage: string) => {
    window.open(`/${currentLanguage}/my-account/my-orders?order-statuses=awaitingPayment`, '_blank');
};

export const BIDDING_NOT_ALLOWED = 'biddingNotAllowed';

export type BannerBlockedType = CompanyOrContactBlocked | typeof BIDDING_NOT_ALLOWED;

type BlockedBannerProps = {
    t: (key: string) => string;
    currentLanguage: string;
    blockedType?: BannerBlockedType;
    companyBlockedReasonId?: number;
    isCommunityMember?: boolean;
    dismissable?: boolean;
    isMainContact?: boolean;
    mainContactName?: string;
    contactEmail?: string;
    onSendInactivityEmailVerification?: () => void;
};

const BlockedBanner = ({
    t,
    currentLanguage,
    blockedType,
    companyBlockedReasonId,
    isCommunityMember = false,
    dismissable = false,
    isMainContact = true,
    mainContactName = '',
    contactEmail = '',
    onSendInactivityEmailVerification,
}: BlockedBannerProps) => {
    const isBiddingNotAllowed = blockedType === BIDDING_NOT_ALLOWED;
    const { showBanner, closeBanner } = useBanner({
        dependencyData: isBiddingNotAllowed ? blockedType : null,
        nameInSessionStorage: BannerSessionStorageKeys.BlockedFromBidding,
    });

    const isAwaitingPayment = companyBlockedReasonId === 18;
    const isBiddingBlockedForInactivity = isBiddingNotAllowed && companyBlockedReasonId === 26;

    const title = isBiddingNotAllowed ? t(`blockedBanner.title.blockedFromBidding`) : t(`blockedBanner.title.companyOrContactBlocked`);
    let descriptionText = companyBlockedReasonId ? t(`blockedBanner.reason.${companyBlockedReasonId}`) : t(`blockedBanner.reason.other`);
    let additionalDescription = isBiddingNotAllowed && isCommunityMember ? ` ${t(`blockedBanner.description.canStillBidOnCommunityAuctions`)}` : '';

    if (isBiddingBlockedForInactivity) {
        descriptionText = isMainContact
            ? extrapolate(t('blockedBanner.action.descBlockedMainContact'), contactEmail)
            : extrapolate(t('blockedBanner.action.descBlockedOtherContact'), mainContactName);
        additionalDescription = '';
    }
    const action =
        isBiddingBlockedForInactivity && isMainContact
            ? {
                  title: t(`blockedBanner.action.sendEmail`),
                  onClick: () => {
                      onSendInactivityEmailVerification && onSendInactivityEmailVerification();
                  },
              }
            : isAwaitingPayment
            ? { title: t(`blockedBanner.action.payYourVehicle`), onClick: () => goToCmsAwaitingPayment(currentLanguage) }
            : { title: t(`blockedBanner.action.moreInformation`), onClick: () => goToCmsBlockedPage(currentLanguage) };

    const Description = () => (
        <>
            {descriptionText}
            {additionalDescription && (
                <>
                    <br />
                    {additionalDescription}
                </>
            )}
        </>
    );

    return showBanner ? (
        <InfoBanner
            variant="error"
            style="ghost"
            title={title}
            actionProps={{ onClick: () => action.onClick() }}
            actionText={action.title}
            description={<Description />}
            showCloseIcon={dismissable}
            onClose={closeBanner}
        />
    ) : null;
};

export default BlockedBanner;
