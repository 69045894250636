import { formatDate, isAfter, parseDate } from '../../utils/date';
import { batchTypeFromBatchAttributeId, EXTENDED_PHASE_STATUS, AUCTION_STATUS } from '../cotw.js';
import { distinct } from '../../utils';
import { belgiumDateTimeToLocal } from '../../utils/date';

// maps internal name for criteria to name to be used when querying the search-service.
export const rangeSearchQueryNameMapping = {
    mileages: 'MilageRange',
    prices: 'PriceRange',
    registrationYears: 'RegistrationYearRange',
    engineSizes: 'EngineSizeRange',
    horsePowers: 'HPRange',
    kWs: 'KWRange',
    co2: 'CO2Range',
};

export const checkboxSearchQueryNameMapping = {
    bodyTypes: 'BodyTypes',
    fuelTypes: 'FuelTypes',
    transmissionTypes: 'Transmissions',
    damageTypes: 'Damages',
    effluentStandards: 'EffluentStandards',
    bodyColors: 'BodyColors',
    auctionTypes: 'AuctionTypes',
    originCountries: 'OriginCountryIds',
    sellers: 'SellerIds',
    sellerGroups: 'SellerGroups',
    equipments: 'Equipment',
    vatRegimes: 'VatRegimes',
    xTimes: 'WillBeExtended',
    assignment100pc: 'AssignmentType',
    isCarOnStock: 'IsCarOnStock',
    premiumOffer: 'PremiumOffer',
    isLocalDealerCar: 'IsDealerCar',
    vehicleGrades: 'VehicleGradeIds',
    mechanicalIndicators: 'MechanicalIndicatorIds',
    places: 'Places',
};

// maps internal name for criteria to facets names returned by the search-service.
export const facetsMapping = {
    makesModels: 'CleanMake', // apply facets count to make only not models.
    fuelTypes: 'FuelGroup',
    bodyTypes: 'Size',
    originCountries: 'CarCountryExtended',
    transmissionTypes: 'GearboxGroupSearch',
    bodyColors: 'BodyColorGroup',
    effluentStandards: 'EffluentStandardGroupSearch',
    equipments: 'OptionsSearch',
    auctionTypes: 'BatchAttributeId',
    damageTypes: 'AccidentCar',
    vatRegimes: 'VatRegime',
    xTimes: 'WillBeExtended',
    assignment100pc: 'Is100PercAssignment',
    //sellers: 'SellerGroup',
    sellerGroups: 'SellerGroup',
    isCarOnStock: 'IsCarOnStock',
    premiumOffer: 'PremiumOffer',
    isLocalDealerCar: 'IsDealerCar',
    vehicleGrades: 'VehicleGradeId',
    mechanicalIndicators: 'MechanicalIndicatorId',
    places: 'Places',
};

export const metadataFields = {
    searchCriteriaNamesForCheckboxeCriteria: Object.keys(checkboxSearchQueryNameMapping),
    searchCriteriaNamesForRangeCriteria: Object.keys(rangeSearchQueryNameMapping),
};

export function signalrAuctionDataToState(data, state) {
    /* data=
        AuctionBatchId : 0 
        AuctionId : 2915340
        BatchEndDate : "0001-01-01T00:00:00"
        CarId : 1919263
        ContactId : 19866
        CurrentPrice : 15100
        HighestBidderId : 19866
        LastUpdated : "2018-08-31T14:14:59.037+02:00"
        MaxPersonalPrice : 15100                            // !! this is not the price of current user.
        NextMaxPrice : 15200
    */

    const auction = state.carSearch.auctionsList.filter(a => a.auctionId === data.AuctionId)[0];
    const isBlind = auction.isBlind;
    const currentUserContactId = state.common.currentUser.contactId;

    const hasUserPlacedABid = data.ContactId === currentUserContactId || (auction && auction.hasUserPlacedABid);
    const result = {
        isUserHighestBidder: data.HighestBidderId === currentUserContactId,
        hasUserPlacedABid,
    };

    if (isBlind) {
        if (data.ContactId === currentUserContactId) {
            result.currentPrice = data.MaxPersonalPrice; // !! data.CurrentPrice = 0 for blind
        }
    } else {
        result.currentPrice = data.CurrentPrice;
        if (auction.isBuyNow) {
            result.isSold = data.CurrentPrice >= auction.buyNowPrice;
        }
    }
    return result;
}

// parse mobile formatted car title (because some parts requires specific formatting).
function carTitle(title) {
    const [makeModel, ...rest] = title.split(' - ');
    // extract mileage (last item)
    const mileage = rest.splice(-1)[0];
    const subTitle = rest.join(' - ');
    return {
        makeModel,
        subTitle,
        mileage,
    };
}

/*
function biddingInfo (bids, contactId) {
    const hasUserPlacedABid = bids.filter(a => a.ContactId === contactId).length > 0;
    let isUserHighestBidder = false;
    if (bids.length > 0) {
        const maxPrice = Math.max(...bids.map(a => a.Price));
        const maxBid = bids.filter(a => a.Price === maxPrice)[0];
        isUserHighestBidder = maxBid.ContactId === contactId;
    }
    return {hasUserPlacedABid, isUserHighestBidder};
}
*/

export function auctionToState(auction, currentLanguage, contactId) {
    const isXTime = auction.ExtendedPhaseType > -1;
    const isAlphabetLikeXTime = auction.ExtendedPhaseType === 2;
    const isLeasePlanLikeXTime = auction.ExtendedPhaseType === 0;

    const endDate =
        isXTime && auction.EndDateExtendedPhase && auction.EndDateExtendedPhase !== '0001-01-01T00:00:00'
            ? parseDate(belgiumDateTimeToLocal(auction.EndDateExtendedPhase))
            : parseDate(belgiumDateTimeToLocal(auction.BatchEndDate));
    const isClosed = /*isAlphabetLikeXTime ?*/ auction.ExtendedPhaseStatusId === EXTENDED_PHASE_STATUS.CLOSED; /*: false*/
    const isUkCar = auction.BatchEcadisCountryId && auction.BatchEcadisCountryId === 9;
    const isElectricCar = auction.CarIdentification.FuelGroup === 'Electric';
    const co2IsUndefined = !auction.Co2 || auction.Co2 === 0;
    return {
        //...auction,

        auctionId: auction.AuctionId,
        thumbnailUrl: auction.ThumbnailUrl ? auction.ThumbnailUrl.replace(/\\/g, '/') : '/v6/images/car-placeholder.png',
        picturesCount: auction.PictureCount,
        currentPrice: auction.CurrentPrice || 0,
        isBuyNow: auction.IsBuyNow,
        buyNowPrice: auction.BuyNowPrice,
        requestedSalesPrice: auction.RequestedSalesPrice,
        hp: auction.Hp,
        kw: auction.Kw,
        dateFirstRegistration: formatDate(auction.DateFirstRegistration, 'short'),

        effluentStandard: auction.EffluentStandardGroupSearch,
        co2: isElectricCar ? (co2IsUndefined ? 0 : auction.Co2) : co2IsUndefined ? '-' : auction.Co2,
        following: false, // cfr prop mappings above

        ...batchTypeFromBatchAttributeId(auction.IsBuyNow, auction.BatchAttributeId),

        hasUserPlacedABid: auction.HasUserPlacedABid,
        isUserHighestBidder: auction.IsUserHighestBidder,
        //...biddingInfo (auction.Bids, contactId),

        bodyType: auction.Size,
        sellingOfficeCountryId: auction.CarCountryExtended ? auction.CarCountryExtended.toLowerCase() : auction.CarCountryExtended,

        isMargin: auction.IsMargin,
        isSold: auction.HasBuyNowBidPlaced, // cfr IsSold prop in CachedAuctionDetailItem.cs (carsite)
        isXTime,
        isAlphabetLikeXTime,
        isLeasePlanLikeXTime,
        isInExtendedPhase: auction.IsInExtendedPhase,
        isExtendedPhaseFinished: auction.IsExtendedPhaseFinished,

        endDate,
        isClosed,

        hasTechnicalDamage: auction.HasTechnicalDamage,
        hasBodyDamage: auction.IsBroken,

        carTitle: {
            ...carTitle(auction.CarTitleList && auction.CarTitleList[currentLanguage]),
            //mileage: auction.Mileage,
            registrationYear: auction.YearFirstRegistration || auction.DateFirstRegistration.slice(0, 4),
        },
        targetPrice: auction.TargetPrice,

        sellerScore: Math.round(auction.SellerScore || 0),

        extendedPhaseStatus: auction.ExtendedPhaseStatusId,
        /*
            Preparing = 1,
            InQueue = 2,
            InXtime = 3,
            Closed = 4,
        */
        extendedPhaseOrder: auction.OrderId,

        extendedPhaseBidOptions: [
            auction.CurrentPrice + auction.Increment,
            auction.CurrentPrice + 2 * auction.Increment,
            auction.CurrentPrice + 3 * auction.Increment,
        ],

        extendedPhaseType: auction.ExtendedPhaseType,

        auctionStatus: auction.StatusId || AUCTION_STATUS.APPROVED,

        // auction.PremiumOffer is a string of comma separated items: 'fastRelease,highChance'
        // converted to a set of flags {fastRelease: true, highChance: true, adesaBest: false, 100pcVerified: false}
        premiumOffer: auction.PremiumOffer
            ? ['fastRelease', 'highChance', 'adesaBest', '100pcVerified', 'describedCorrectly', 'exclusiveAtAdesa'].reduce((hash, p) => {
                  hash[p] = auction.PremiumOffer.indexOf(p) !== -1;
                  return hash;
              }, {})
            : null,
        dealerDiscount: auction.DealerDiscount,
        communityId: auction.CommunityId,
        communityName: auction.CommunityName,
        vehicleGradeId: auction.VehicleGradeId,
        mechanicalIndicatorId: auction.MechanicalIndicatorId,
        currencyCode: auction.CurrencyCodeId, //Find cars search page
        isUkVehicle: isUkCar,
        isVatIncludedInBidAmount: isUkCar && auction.IsMargin,
        is247StockSale: auction.BatchAttributeId && auction.BatchAttributeId === 6,
        auctionFeeDiscount: auction.ApplyAuctionFeeDiscount && auction.AuctionFeeDiscount ? auction.AuctionFeeDiscount : null,
        isNew: isAfter(auction.DateTillShowNewLabel, new Date()),
        previousPrice: auction.PreviousPrice,
        isNonTransactionalAuction: auction.IsNonTransactional,
        isCommunityAuction: !!auction.CommunityId,
        mileageTypeId: auction.MileageTypeId,
        isStock: auction.IsStock,
    };
}

export function savedQueryToState(savedQuery) {
    const query = JSON.parse(savedQuery.SearchRequest);
    // remove duplicates in criteria values.
    Object.keys(checkboxSearchQueryNameMapping).forEach(
        k => (query.searchCriteria[k] = query.searchCriteria[k] ? query.searchCriteria[k].filter(distinct) : []),
    );
    // old saved search doesn't have the vehicleLocation field (introduced later).
    // add it to avoid an error.
    // cfr https://karauctionservices.visualstudio.com/International/_workitems/edit/666395
    if (!query.searchCriteria.vehicleLocation) {
        query.searchCriteria.vehicleLocation = {};
    }
    return {
        id: savedQuery.Id,
        name: savedQuery.Title,
        query,
        sendMail: savedQuery.SendMail,
        createdOn: savedQuery.DateCreated,
        viewedCount: savedQuery.ViewedCount,
    };
}
